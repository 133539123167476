import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from 'moment';

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton, SelectInput, Textarea } from "../Form/Form";
import { NAME_LIMIT} from "../../util/other";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    setValue,
    control
  } = useForm();

  const { response, request }                                     = useRequest();
  const [image, setImage]                                         = useState();
  const [displayImage, setDisplayImage]                           = useState();
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Coach - Calevalab";
  }, []); 
  
  useEffect(() => {
    if (response) {
      toast.success("Coach has been added successfully.");
      history.push("/coaches");
    }
  }, [response]);

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      organizationCode,
      experience,
      qualification,
      certifications,
      bio,
      email,
      password,
      confirmpassword,
    } = data;

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setError("email", {
        type: "manual",
      });
      return;
    }

    if (password !== confirmpassword) {
      setError("confirmpassword", {
        type: "manual",
      });
      return;
    }

    let fd = new FormData();

   
    if (data.image && data.image.length > 0) {
      if (data.image[0].type) {
        const typeArr =  data.image[0].type.split("/");
        if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
          setError("image", {
            type: "manual",
          });
          return;
        }else{
            fd.append("file", data.image[0]);
        }
      }
    }
    fd.append("firstName", firstName);
    fd.append("lastName", lastName);
    fd.append("organizationCode", organizationCode);
    fd.append("password", password);
    fd.append("email", email);
    fd.append("experience", experience);
    fd.append("qualification", qualification);
    fd.append("certifications", certifications);
    fd.append("bio", bio);
    request("POST", "coach", fd);
  };



  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const typeArr = event.target.files[0].type.split("/");
      if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
        setError("image", {type:"manual"});
        setValue("image", event.target.files[0]);
        setDisplayImage("")
        return;
      }

      setDisplayImage(URL.createObjectURL(event.target.files[0]));

      clearErrors("image")
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }

  };

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "firstName",
        registerFields: {
          required: true,
          // pattern: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
          pattern:/^[\S.]+(?:[\s.]+[\S.]+)*$/,
          maxLength:NAME_LIMIT,
          isNameField:true
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid first name.",
        },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "lastName",
        registerFields: {
          required: true,
          pattern:/^[\S.]+(?:[\s.]+[\S.]+)*$/,
          maxLength:NAME_LIMIT,
          isNameField:true
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid last name.",
        },
      },
      {
        Component: Input,
        label: "Organization Invitation Code",
        type: "text",
        name: "organizationCode",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Experience(in years)",
        type: "text",
        name: "experience",
        registerFields: {
          required: true,
          pattern: /^\d{1,3}(,\d{3})*(\.\d+)?$/i
        },
        registerFieldsFeedback: {
          pattern: "The field experience must contain numbers or decimal values.",
        },
      },
      {
        Component: Input,
        label: "Qualification",
        type: "text",
        name: "qualification",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Cerifications",
        type: "text",
        name: "certifications",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Textarea,
        label: "Description/Bio",
        name: "bio",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Profile Image",
        type: "file",
        name: "image",
        registerFields: {
          required: false,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid image extension type.",
        },
      },
    ],

    [
      {
        Component: Input,
        label: "Password",
        type: "password",
        name: "password",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&]).{10,}/,
        },
        registerFieldsFeedback: {
          pattern:"Password must be of 10 characters long with atleast one Uppercase, one Lowercase, one Number and one Special Character.",
        },
      },
      {
        Component: Input,
        label: "Confirm Password",
        type: "password",
        name: "confirmpassword",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&]).{10,}/,
        },
        registerFieldsFeedback: {
          pattern:"Confirm password must be of 10 characters long with atleast one Uppercase, one Lowercase, one Number and one Special Character.",
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Both Passwords do not match.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Coach"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/coaches", name: "Back To Coaches" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Coach
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
