import React,  { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton,Textarea } from "../Form/Form";
import { CHANGES_REQ_MESSAGE, NAME_LIMIT} from "../../util/other";
import { compareChanges } from "../../util/fn";

const Edit = (props) => {
  const { id: seekerId } = props.match.params;
  const history = useHistory();

  const [oldValues, setOldValues]                     = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    control
  } = useForm();

  const { response: responseFetchUser, request: requestFetchSeeker } =
    useRequest();

  const { response, request } = useRequest();
 
  const [image, setImage]                                         = useState();
  const [displayImage, setDisplayImage]                           = useState();

  useEffect(() => {
    if (seekerId) {
      requestFetchSeeker("GET", `coach/${seekerId}`);
      document.title = "Edit Coach - Calevalab";
    }
  }, [seekerId]);

  useEffect(() => {
    if (responseFetchUser) {
      const { firstName, lastName, email,organizationCode,other_fields } = responseFetchUser.user;

      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      setValue("organizationCode", organizationCode);
      setValue("bio", (other_fields && other_fields.bio) ? other_fields.bio:'');
      setValue("experience", (other_fields && other_fields.experience) ? other_fields.experience:'');
      setValue("qualification", (other_fields && other_fields.qualification) ? other_fields.qualification:'');
      setValue("certifications", (other_fields && other_fields.certifications) ? other_fields.certifications:'');
      setValue("image", (other_fields && other_fields.profilePic) ? other_fields.profilePic:'');


      setOldValues({
        firstName: firstName,
        lastName: lastName,
        email: email,
        organizationCode: organizationCode,
        bio: other_fields?.bio || '',
        experience: other_fields?.experience || '',
        qualification: other_fields?.qualification || '',
        certifications: other_fields?.certifications || '',
        image: other_fields?.profilePic || ''
      });
      setImage(other_fields && other_fields.profilePic ? other_fields.profilePic:'');
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Coach has been updated successfully.");
      history.push("/coaches");
    }
  }, [response]);

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const typeArr = event.target.files[0].type.split("/");
      if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
        setError("image", {type:"manual"});
        setValue("image", event.target.files[0]);
        setDisplayImage("")
        setImage("");
        return;
      }
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      clearErrors("image")
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
  };

  const onSubmit = (data) => {
    const {  firstName,
        lastName,
        organizationCode,
        experience,
        qualification,
        certifications,
        bio,
        email} = data;

    const { email: oldEmail } = responseFetchUser.user;

    let updates = {};

    if (email.trim() != oldEmail) {
      updates.email = email;
    }

    let fd = new FormData();

    if(displayImage){
      if (data.image && data.image.length > 0) {
        if (data.image[0].type) {
          const typeArr =  data.image[0].type.split("/");
  
          if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
            setError("image", {
              type: "manual",
            });
            return;
          }else{
              fd.append("file", data.image[0]);
          }
        }
      }
    }else{
      if(image){
          fd.append("old_image", image);
      }else{
        if (data.image && data.image.length > 0) {
          if (data.image[0].type) {
            const typeArr =  data.image[0].type.split("/");
    
            if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
              setError("image", {
                type: "manual",
              });
              return;
            }else{
                fd.append("file", data.image[0]);
            }
          }
        }
      }
    }
  

    fd.append("firstName", firstName);
    fd.append("lastName", lastName);
    fd.append("organizationCode", organizationCode);
    fd.append("experience", experience);
    fd.append("qualification", qualification);
    fd.append("certifications", certifications);
    fd.append("bio", bio);
    fd.append("email", email);
    fd.append("userId", seekerId);

    let ignoreKey = [];
    if(compareChanges(oldValues,data,ignoreKey)){
      request("PUT", "coach", fd);
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "firstName",
        registerFields: {
          required: true,
          pattern: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
          maxLength:NAME_LIMIT,
          
        },
        registerFieldsFeedback: {
          pattern: "First name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "lastName",
        registerFields: {
          required: true,
          pattern: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
          maxLength:NAME_LIMIT,
        },
        registerFieldsFeedback: {
          pattern: "Last name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Organization Invitation Code",
        type: "text",
        name: "organizationCode",
        registerFields: {
          required: true,
        },
      },
    
      {
        Component: Input,
        label: "Experience(in years)",
        type: "text",
        name: "experience",
        registerFields: {
          required: true,
          pattern: /^\d{1,3}(,\d{3})*(\.\d+)?$/i
        },
        registerFieldsFeedback: {
          pattern: "The field experience must contain numbers or decimal values.",
        },
      },
      {
        Component: Input,
        label: "Qualification",
        type: "text",
        name: "qualification",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Cerifications",
        type: "text",
        name: "certifications",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Textarea,
        label: "Description/Bio",
        name: "bio",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
     
      
      {
        Component: Input,
        label: "Profile Image",
        type: "file",
        name: "image",
        registerFields: {
          required: false,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid image extension type.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Coach"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/coaches" /*backPageNum: page */ },
            name: "Back To Coaches",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Coach
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
