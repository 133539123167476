export const API = {
  PORT:
    process.env.NODE_ENV == "development"
      ? "http://calevalab.dev.obdemo.com:7103"
      : "https://api-dev.calevalab.com"
};

// https://calevalabapi.stage04.obdemo.com


export const BASEURL = {
  PORT:
    process.env.NODE_ENV == "development"
      ? "http://calevalab.dev.obdemo.com:7103"
      : "https://api-dev.calevalab.com"
};

// "https://calevalabapi.stage04.obdemo.com"
// "https://api-dev.calevalab.com"
export const FRONTENDURL =
  process.env.NODE_ENV == "development"
    ? "http://calevalab.dev.obdemo.com:7101"
    : "https://dev.calevalab.com/"

// "https://calevalab.stage04.obdemo.com/";
// "https://dev.calevalab.com/"
