import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";


import {
  Input,
  RenderInputFields,
  SubmitButton,
  Textarea,SubInput,
  SubTab,
  SelectInput
} from "../Form/Form";
import { CHANGES_REQ_MESSAGE, DefaultCountryCode } from "../../util/other";
import { compareChanges } from "../../util/fn";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm();

  const { response, request } = useRequest();
  const { languages } = useSelector((state) => state.setting);
  const { response: responseContactUs, request: requestContactUs } = useRequest();
  const { request: requestCCodes, response: responseCCodes}       = useRequest();

  const [recordId, setRecordId] = useState();
  const [allCountryCodes, setAllCountryCodes]                     = useState([]);
  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    document.title = "Contact Us Setting - Calevalab";
    requestContactUs("GET", "contact-us-setting");
    requestCCodes("GET",`country-codes/all`)
  }, []);

  useEffect(() => {
    if(responseCCodes && responseCCodes.status){
      setAllCountryCodes(responseCCodes?.data?.length ? responseCCodes?.data : [])
    }
  },[responseCCodes])

  useEffect(() => {
    if (responseContactUs) {

      console.log("DefaultCountryCode",DefaultCountryCode);
        const {langData, _id, address, contact, fax, countryCode, faxCountryCode, email, title, description} = responseContactUs?.data ?? {};
        if (_id) {
          setRecordId(_id);
        }
        setValue("title", title ?? "");
        setValue("description", description ?? "");
        setValue("address", address ?? "");
        setValue("email", email ?? "");
        setValue("contact", contact ?? "");
        setValue("fax", fax ?? "");
        
        console.log("id",countryCode)
        console.log("allCountryCodes",allCountryCodes)
        if(countryCode){
          setValue("countryCode", countryCode ?? "");
        }else{

          if(allCountryCodes && allCountryCodes.length>0){
            const selectedCodeObj = allCountryCodes.find(obj => {
              if(obj.dial_code == DefaultCountryCode.CountryCode){
                return obj;
              } 
            })
            setValue('countryCode',selectedCodeObj?._id)
          }
        }
        if(faxCountryCode){
          setValue("faxCountryCode", faxCountryCode ?? "");
        }else{
          if(allCountryCodes && allCountryCodes.length>0){
            const selectedCodeObj = allCountryCodes.find(obj => {
              if(obj.dial_code == DefaultCountryCode.CountryCode){
                return obj;
              } 
            })
            setValue('faxCountryCode',selectedCodeObj?._id)
          }
        }
        const subData   = {};
        if(langData?.length>0){
          langData.forEach((lang) => {
            const code = lang?.languageCode;
            subData["id-" + code] = lang?._id;
            subData["title-" + code] = lang?.title;
            subData["description-" + code] = lang?.description;
            setValue(`id-${code}`, lang?._id)
            setValue(`title-${code}`, lang?.title)
            setValue(`description-${code}`, lang?.description)
          });
          // reset({ ...subData });
      
        }
        const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
        const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
        setOldValues({
          address:address,
          countryCode: countryCode,
          faxCountryCode:faxCountryCode,
          contact:contact,
          fax:fax,
          email:email,
          langData:langDataShortedOld
        });
    }
  }, [responseContactUs,allCountryCodes]);


  useEffect(() => {
    if (response) {
      if (response.data) {
        if (response.data._id) {
          setRecordId(response.data._id);
        }
      }
      toast.success(response.message);
    }
  }, [response]);

  const onSubmit = (data) => {
    
    const { address, countryCode, faxCountryCode, contact, fax, email} = data;
    const defaultData                   = {address, countryCode, faxCountryCode, contact, fax, email};
    const langData                      = [];

    for (let i = 0; i < languages.length; i++) {
        const code = languages[i].code;
  
        if (languages[i].default) {
          defaultData.title = data?.["title-" + code];
          defaultData.description = data?.["description-" + code];;
        }
        langData.push({ languageCode:code, id: data[`id-${code}`], title: data[`title-${code}`], description: data[`description-${code}`]});
      }

      const langDataWithoutId = langData.map(({ id: _, ...rest }) => rest);
      const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
  
      let newData = {
        address: address,
        countryCode: countryCode,
        faxCountryCode: faxCountryCode,
        contact: contact,
        fax: fax,
        email: email,
        langData:langDataShorted
      };
      console.log(oldValues,'oldValues',newData,'newValues');

      if(compareChanges(oldValues,newData)){
        if (recordId) {
            request("PUT", "contact-us-setting", {...defaultData,id:recordId, langData});
        } else {
            request("POST", "contact-us-setting", {...defaultData,langData});
        }
      }
      else {
        toast.error(CHANGES_REQ_MESSAGE);
      }
  };

  const InputFields = [
    [
        {
            Component: Input,
            label: "Title",
            type: "text",
            name: "title",
            registerFields: {
              required: true,
              // pattern: /^[A-Za-z!@#$%^&*() ]+$/,
              setValueAs: (v) => v.trim(),
            },
            // registerFieldsFeedback: {
            //   pattern: "Title can only contain letters.",
            // },
          },
      {
        Component: Textarea,
        placeholder: "Enter Description",
        label: "Description",
        name: "description",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ]
  ];

  const InputFieldsSingle = [
    [ 
      {
        Component: Input,
        label: "Address",
        type: "text",
        name: "address",
        registerFields: {
          required: false,
          setValueAs: (v) => v.trim(),
        },
      },
      {
        Component: SelectInput,
        label: "Country Code",
        name: "countryCode",
        colClass:"col-xl-2",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        extraClass:"countryCode-input",
        children: (
          allCountryCodes && allCountryCodes.length > 0 
          ? allCountryCodes.map(obj => ({
              value: obj._id,
              label: obj.dial_code,
              selected: obj.dial_code === DefaultCountryCode?.CountryCode
            }))
          : []
        ),
        control:control
      },
      {
        Component: Input,
        label: "Phone Number",
        type: "text",
        name: "contact",
        colClass:"col-xl-4",
        registerFields: {
          required: false,
          pattern: /^[0-9]{7,12}$/gm,
        },
        registerFieldsFeedback: {
          pattern: "Phone number must be minimum 7 and maximum 12 digit number.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "text",
        name: "email",
        registerFields: {
            required: false,
            pattern:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          },
          registerFieldsFeedback: {
            pattern: "The email field must be a valid email address.",
          },
      },
      {
        Component: SelectInput,
        label: "Country Code",
        name: "faxCountryCode",
        colClass:"col-xl-2",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        extraClass:"countryCode-input",
        children: (
          allCountryCodes && allCountryCodes.length > 0 
          ? allCountryCodes.map(obj => ({
              value: obj._id,
              label: obj.dial_code,
              selected: obj.dial_code === DefaultCountryCode?.CountryCode
            }))
          : []
        ),
        control:control
      },
      {
        Component: Input,
        label: "Fax",
        type: "text",
        name: "fax",
        colClass:"col-xl-4",
        registerFields: {
          required: false,
          pattern: /^[0-9]{7,12}$/gm,
        },
        registerFieldsFeedback: {
          pattern: "Fax must be minimum 7 and maximum 12 digit number.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Contact Us Setting"
        links={[
          { to: "/", name: "Dashboard" },
          // { to: "/home/service-descriptions", name: "Back To Service Descriptions" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">Contact Us Setting</h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                />
                             ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <>
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  InputFields={InputFields}
                                  code={lang.code}
                                />
                              </>
                            ))}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                  
                      <RenderInputFields
                        InputFields={InputFieldsSingle}
                        errors={errors}
                        register={register}
                      />
                      
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
