import React, { useEffect, useState,useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  RenderInputFields,
  SubmitButton,
  Input,
} from "../Form/Form";

const Edit = (props) => {

  const { id: languageId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
     error,
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    
    clearErrors,
    control
  } = useForm();

  const { response: responseFetchLanguage, request: requestFetchLanguage } =
    useRequest();

  const { response, request } = useRequest();
  const [image, setImage]                                         = useState();
  const [ displayImage,  setDisplayImage]                           = useState();


  useEffect(() => {
    if (languageId) {
      requestFetchLanguage("GET", `language/${languageId}`);
      document.title = "Edit Language - Calevalab";
    }
  }, [languageId]);

  useEffect(() => {
    if (responseFetchLanguage) {
      const { language, icon} = responseFetchLanguage;

      reset({ language, icon });
    }
  }, [responseFetchLanguage]);
  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const typeArr = event.target.files[0].type.split("/");
      if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
        setError("icon", {type:"manual"});
        setValue("icon", event.target.files[0]);
         setDisplayImage("")
        return;
      }
       setDisplayImage(URL.createObjectURL(event.target.files[0]));
      clearErrors('icon')
    } else {
       setDisplayImage("");
      setImage("");
      setValue("icon", "");
    }
  };

  const handleRemoveImage = () => {
    if ( displayImage) {
       setDisplayImage(null);
      setValue("icon", null);
    } else {
      setImage(null);
    }
  };
  useEffect(() => {
    if (response) {
      toast.success("Language has been updated successfully.");
      history.push("/Languages");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { icon, language} = data;
    let fd = new FormData();
    if (icon && icon.length > 0) {
        if (icon[0].type) {
          const typeArr =  icon[0].type.split("/");
          if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
            setError("icon", {
              type: "manual",
            });
            return;
          }
          fd.append("file", icon[0]);
        }
    }
    fd.append("id", languageId);
    fd.append("language", language);
    request("PUT","language",fd);
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Language",
        name: "language",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "icon",
        registerFields: {
          required: true,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid image extension type.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Language"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/languages" },
            name: "Back To Languages",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Language
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
