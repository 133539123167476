import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { Input, RenderInputFields, SubmitButton,SubInput,
  SubTab } from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue
  } = useForm();

  const { response, request } = useRequest();
  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const [image, setImage]                                         = useState();
  const [displayImage, setDisplayImage]                           = useState();

  useEffect(() => {
    document.title = "Add Sport - Calevalab";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Sport has been added successfully.");
      history.push("/sports");
    }
  }, [response]);

  const onSubmit = (data) => {
    const dataToSend = [];
    const defaultData = {};

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;
      dataToSend.push({
        languageCode: code,
        name: data["name-" + code] ?? "",
      });

      if (languages[i].default) {
        defaultData.name = data["name-" + code];
      }
    }
    request("POST", "master/sport", { ...defaultData, langData: JSON.stringify(dataToSend) });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          // pattern: /^(?!\s*$)[A-Za-z ]+$/,
          // pattern:/^[\S.]+(?:[A-Za-z\s.]+[\S.]+)*$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Name can only contain letters and not starts and ends with a space."
        // },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Sport"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/sports", name: "Back To Sports" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Sport
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                />
                             ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <>
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  InputFields={InputFields}
                                  code={lang.code}
                                />
                              </>
                            ))}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
