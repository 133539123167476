import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";
import { compareChanges } from "../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../util/other";

const Add = () => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError
  } = useForm();

  const { response, request }                                     = useRequest();
  const { response:responseAll, request:requestAll }              = useRequest();
  const { response:responseEdit, request:requestEdit }            = useRequest();
  const [isEdit, setIsEdit]                                       = useState(false);
  const [oldValues, setOldValues]                     = useState([]);
  const history = useHistory();

  useEffect(() => {
    document.title = "RPE Color Codes - Calevalab";
    getAll();
  }, []);

  const getAll = () => {
    requestAll("GET","rpe-color-code/all")
  }
  
  useEffect(() => {
    if (responseAll) {
        if(responseAll.status){
            if(responseAll?.data?.length>0){
                setIsEdit(true);
                responseAll?.data.forEach((data,i) => {
                    setValue(`colorCode${i}`,data.colorCode)
                    setValue(`from${i}`,data.from)
                    setValue(`to${i}`,data.to)
                    setValue(`id${i}`,data._id)
                })

                const oldValues = responseAll?.data.map((data, i) => ({
                  [`from`]: data.from,
                  [`to`]: data.to,
                  [`colorCode`]: data.colorCode
                }));
                setOldValues(oldValues);
            }
        }
    }
  }, [responseAll]);

  useEffect(() => {
    if (response) {
    getAll();
      toast.success("RPE color code has been added successfully.");
    //   history.push("/rpe-color-codes");
    }
  }, [response]);

  useEffect(() => {
    if (responseEdit) {
      getAll();
      toast.success("RPE color code has been updated successfully.");
    }
  }, [responseEdit]);

  const onSubmit = (data) => {
    const {id0, id1, id2, id3, colorCode0, colorCode1, colorCode2, colorCode3, from0, from1, from2, from3, to0, to1, to2, to3 } = data;

    // let name0 = from0 + "% - " + to0 + "%";
    // let name1 = from1 + "% - " + to1 + "%";
    // let name2 = from2 + "% - " + to2 + "%";
    // let name3 = from3 + "% +";

    let errors = 0;
    if (parseInt(from0) > parseInt(to0)) {
      setError("from0", {
        type: "manual",
      });
      errors = 1;
    }

    if (parseInt(from1) > parseInt(to1)) {
      setError("from1", {
        type: "manual",
      });
      errors = 1;
    }
    if (parseInt(from2) > parseInt(to2)) {
      setError("from2", {
        type: "manual",
      });
      errors = 1;
    }

    if (parseInt(from3) > parseInt(to3)) {
      setError("from3", {
        type: "manual",
      });
      errors = 1;
    }

    if(errors == 1){
      return;
    }

    if(compareChanges(oldValues,[
      { from: parseInt(from0), to: parseInt(to0), colorCode: colorCode0},
      { from: parseInt(from1), to: parseInt(to1), colorCode: colorCode1 },
      { from: parseInt(from2), to: parseInt(to2), colorCode: colorCode2},
      { from: parseInt(from3), to: parseInt(to3), colorCode: colorCode3},
    ])){
      if(id0 && id1 && id2 && id3){
        requestEdit("PUT", "rpe-color-code", {rpeColor:JSON.stringify([
            { from: parseInt(from0), to: parseInt(to0), colorCode: colorCode0,id:id0},
            { from: parseInt(from1), to: parseInt(to1), colorCode: colorCode1,id:id1 },
            { from: parseInt(from2), to: parseInt(to2), colorCode: colorCode2,id:id2},
            { from: parseInt(from3), to: parseInt(to3), colorCode: colorCode3,id:id3},
          ])});

    }else{
        request("POST", "rpe-color-code", {rpeColor:JSON.stringify([
            { from: parseInt(from0), to: parseInt(to0), colorCode: colorCode0},
            { from: parseInt(from1), to: parseInt(to1), colorCode: colorCode1 },
            { from: parseInt(from2), to: parseInt(to2), colorCode: colorCode2},
            { from: parseInt(from3), to: parseInt(to3), colorCode: colorCode3},
          ])});
    }
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
  };

  const handleIntervalValue = (event, field) => {

    if (event.target.value != null && event.target.value != "") {
      const value = parseFloat(event.target.value);

     
      if ( /^[0-9]+$/.test(value) ) {
        if (field == "to0") {
            setValue(`from1`, value + 1);
        } else if (field == "to1") {
            setValue(`from2`, value + 1);
        } else if (field == "to2") {
            setValue(`from3`, value + 1);
        } else if (field == "from3") {
            setValue(`to2`, value - 1);
        } else if (field == "from2") {
            setValue(`to1`, value - 1);
        } else if (field == "from1") {
            setValue(`to0`, value - 1);
        }
      }
    }
  };




  const InputFields = [
    [

      
        {
          Component: Input,
          label: "Color Code",
          type: "text",
          name: "colorCode0",
          registerFields: {
            required: true,
            pattern: /^#[0-9A-F]{6}$/i,
          },
          colClass:"col-xl-4",
          registerFieldsFeedback: {
            pattern: "Please enter valid color code.",
          },
        },

        {
            Component: Input,
            label: "From",
            type: "text",
            name: "from0",
            colClass:"col-xl-4",
            registerFields: {
              required: true,
              pattern: /^[0-9]+$/,
            },
            registerFieldsFeedback: {
              pattern: "From can only contain numbers.",
            },
            otherRegisterFields: {
              manual: true,
              feedback: "The from field must not be greater than to field.",
            },
            handleChange:handleIntervalValue,
            control:control
          },
        // {
        //   Component: Input,
        //   label: "From",
        //   type: "text",
        //   name: "from0",
        //   colClass:"col-xl-4",
        //   registerFields: {
        //     required: true,
        //     pattern: /^[0-9 ]+$/,
        //   },
        //   registerFieldsFeedback: {
        //     pattern: "From can only contain numbers.",
        //   },
        //   handleChange:handleIntervalValue,
        //   control:control
        // },
     
        {
          Component: Input,
          label: "To",
          type: "text",
          name: "to0",
          colClass:"col-xl-4",
          registerFields: {
            required: true,
            pattern: /^[0-9]+$/,
          },
          registerFieldsFeedback: {
            pattern: "To can only contain numbers.",
          },
          handleChange:handleIntervalValue,
          control:control
        },
     
      ],
    [
      {
        Component: Input,
        label: "Color Code",
        type: "text",
        name: "colorCode1",
        registerFields: {
          required: true,
          pattern: /^#[0-9A-F]{6}$/i,
        },
        colClass:"col-xl-4",
        registerFieldsFeedback: {
          pattern: "Please enter valid color code.",
        },
      },
      {
        Component: Input,
        label: "From",
        type: "text",
        name: "from1",
        colClass:"col-xl-4",
        registerFields: {
          required: true,
          pattern: /^[0-9]+$/,
        },
        registerFieldsFeedback: {
          pattern: "From can only contain numbers.",
        },
        otherRegisterFields: {
          manual: true,
          feedback: "The from field must not be greater than to field.",
        },
        handleChange:handleIntervalValue,
        control:control
      },
   
      {
        Component: Input,
        label: "To",
        type: "text",
        name: "to1",
        colClass:"col-xl-4",
        registerFields: {
          required: true,
          pattern: /^[0-9]+$/,
        },
        registerFieldsFeedback: {
          pattern: "To can only contain numbers.",
        },
        handleChange:handleIntervalValue,
        control:control
      },
   
    ],
    [
        {
          Component: Input,
          label: "Color Code",
          type: "text",
          name: "colorCode2",
          registerFields: {
            required: true,
            pattern: /^#[0-9A-F]{6}$/i,
          },
          colClass:"col-xl-4",
          registerFieldsFeedback: {
            pattern: "Please enter valid color code.",
          },
          
        },
        {
          Component: Input,
          label: "From",
          type: "text",
          name: "from2",
          colClass:"col-xl-4",
          registerFields: {
            required: true,
            pattern: /^[0-9]+$/,
          },
          registerFieldsFeedback: {
            pattern: "From can only contain numbers.",
          },
          otherRegisterFields: {
            manual: true,
            feedback: "The from field must not be greater than to field.",
          },
          handleChange:handleIntervalValue,
          control:control
        },
     
        {
          Component: Input,
          label: "To",
          type: "text",
          name: "to2",
          colClass:"col-xl-4",
          registerFields: {
            required: true,
            pattern: /^[0-9]+$/,
          },
          registerFieldsFeedback: {
            pattern: "To can only contain numbers.",
          },
          handleChange:handleIntervalValue,
          control:control
        },
     
    ],
    [
    {
        Component: Input,
        label: "Color Code",
        type: "text",
        name: "colorCode3",
        registerFields: {
        required: true,
        pattern: /^#[0-9A-F]{6}$/i,
        },
        colClass:"col-xl-4",
        registerFieldsFeedback: {
        pattern: "Please enter valid color code.",
        },
    },
    {
        Component: Input,
        label: "From",
        type: "text",
        name: "from3",
        colClass:"col-xl-4",
        registerFields: {
        required: true,
        pattern: /^[0-9]+$/,
        },
        
        registerFieldsFeedback: {
        pattern: "From can only contain numbers.",
        },

        otherRegisterFields: {
          manual: true,
          feedback: "The from field must not be greater than to field.",
        },
        handleChange:handleIntervalValue,
        control:control
    },
    
    {
        Component: Input,
        label: "To",
        type: "text",
        name: "to3",
        colClass:"col-xl-4",
        registerFields: {
        required: true,
        pattern: /^[0-9]+$/,
        },
        registerFieldsFeedback: {
        pattern: "To can only contain numbers.",
        },
        handleChange:handleIntervalValue,
        control:control
    },
    
    ],
   
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="RPE Color Codes"
        links={[
          { to: "/", name: "Dashboard" },
         
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                   RPE Color Codes
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name={isEdit ? "Update":"Submit" }
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
