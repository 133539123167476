import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  CKEditorInput,
  SelectInput,
  RenderInputFields,
  SubmitButton,SubInput,
  SubTab 
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    control
  } = useForm();

  const ckEditorRef = useRef();
  const { languages } = useSelector((state) => state.setting);
  const [emailActions, setEmailActions] = useState([]);
  const [emailConstants, setEmailConstants] = useState([]);

  const { response, request } = useRequest();
  const { response: responseEmailActions, request: requestEmailActions } =
    useRequest();
  const { response: responseConstants, request: requestConstants } =
    useRequest();

  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("en");

  useEffect(() => {
    // register("body", { required: true });
    requestEmailActions("GET", "email-action/all");
    document.title = "Add Email Template - Calevalab";
  }, []);


  useEffect(() => {
    ckEditorRef.current = {};
    if (languages) {
      languages.forEach((lang) => {
        register(`body-${lang.code}`, { required: true });
      });
    }
  }, [languages]);

  useEffect(() => {
    if (responseEmailActions) {
      setEmailActions(responseEmailActions.emailActions || []);
    }
  }, [responseEmailActions]);

  useEffect(() => {
    if (responseConstants) {
      setEmailConstants(responseConstants.emailAction.constants);
    }
  }, [responseConstants]);

  useEffect(() => {
    if (response) {
      toast.success("Email Template has been added successfully.");
      history.push("/email-template");
    }
  }, [response]);

  const constantHandler = (id) => {
    if (!id) {
      return;
    }
    requestConstants("GET", `email-action/${id}`);
  };

  const insertConstantHandler = () => {
    const constant = getValues("constant");
    if (constant.length == 0) {
      return;
    }
    ckEditorRef.current[currentTab].editor.insertText(`{${constant}}`);
  };

  const onSubmit = (data) => {
   
    const dataToSend = [];
    const defaultData = {};

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;
      dataToSend.push({
        languageCode: code,
        body: data["body-" + code],
        subject: data["subject-" + code],
      });
      if (languages[i].default) {
        defaultData.name = data["name"];
        defaultData.action = data["action"];
        defaultData.body = data["body-" + code];
        defaultData.subject = data["subject-" + code];
      }
    }
   
    request("POST", "email-template", { ...defaultData, langData: dataToSend});
  };


  const InputFields1 = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
        },
      },
      {
        Component: SelectInput,
        label: "Action",
        name: "action",
        registerFields: {
          required: true,
          minLength: 1,
        },
        handleChange: constantHandler,
        children: (
          [
            { value: "", label: "Select Page Name" },  // Default option
            ...(emailActions && emailActions.length > 0
              ? emailActions.map(obj => ({ value: obj._id, label: obj.action }))
              : []
            )
          ]
          
          // <>
          //   <option value="">{"Select Page Name"}</option>
          //   {emailActions.length > 0 &&
          //     emailActions.map((action) => (
          //       <option key={action._id} value={action._id}>
          //         {action.action}
          //       </option>
          //     ))}
          // </>
        ),
        control:control
      },
    ],
    [
      {
        Component: SelectInput,
        label: "Constants",
        name: "constant",
        registerFields: {},
        children: (
          [
            { value: "", label: "Select Constant" },  // Default option
            ...(emailConstants && emailConstants.length > 0
              ? emailConstants.map((constant, index) => ({ value: constant, key: index ,label:constant}))
              : []
            )
          ]
          // <>
          //   <option value="">{"Select Constant"}</option>
          //   {emailConstants.length > 0 &&
          //     emailConstants.map((constant, index) => (
          //       <option key={index} value={constant}>
          //         {constant}
          //       </option>
          //     ))}
          // </>
        ),
        control:control,

        moreData: (
          <button
            onClick={insertConstantHandler}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
          >
            Insert
          </button>
        ),
      },
      
    ],
  ];

  const handleCurrentTab = (code) => {
    setCurrentTab(code);
  }

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Email Template"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/email-template", name: "Back To Email Template" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Email Template
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                
                    <RenderInputFields
                      InputFields={InputFields1}
                      errors={errors}
                      register={register}
                    />
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                  onClick={() => handleCurrentTab(lang?.code)}
                                />
                             ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => {
                              return(
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  InputFields={[
                                    [
                                      {
                                        Component: Input,
                                        label: "Subject",
                                        type: "text",
                                        name: "subject",
                                        registerFields: {
                                          required: true,
                                        },
                                      },
                                    ],
                                    [{
                                      Component: CKEditorInput,
                                      colClass: "col-xl-12",
                                      label: "Email Body",
                                      name: "body",
                                      registerFields: {
                                        required: true,
                                      },
                                      getValues,
                                      setValue,
                                      trigger,
                                      inputData: {
                                        onInstanceReady: (editor) => {
                                          ckEditorRef.current[lang.code] = editor;
                                        },
                                      },
                                      clearErrors,
                                      isEdit: false,
                                    }]]
                                  }
                                  code={lang.code}
                                />
                              )
                            })}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                   
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
