import React,  { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from 'moment';

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, SelectInput, RenderInputFields, SubmitButton } from "../Form/Form";
import { CHANGES_REQ_MESSAGE, DefaultCountryCode ,NAME_LIMIT } from "../../util/other";
import { compareChanges } from "../../util/fn";

const Edit = (props) => {
  const { id: seekerId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    control
  } = useForm();

  const minDateOfBirth = moment().subtract(1,'days').format("YYYY-MM-DD")+' 23:59:59';

  const { response: responseFetchUser, request: requestFetchSeeker } = useRequest();

  const { response, request } = useRequest();
  const { request: requestSport, response: responseSport}         = useRequest();
  const { request: requestCountry, response: responseCountry}     = useRequest();
  const { request: requestCCodes, response: responseCCodes}       = useRequest();
  const [allSports, setAllSports]                                 = useState([]);
  const [allCountry, setAllCountry]                               = useState([]);
  const [allCountryCodes, setAllCountryCodes]                     = useState([]);
  const [image, setImage]                                         = useState();
  const [displayImage, setDisplayImage]                           = useState();
  const [selectedSport, setSelectedSport]                         = useState();
  const [selectedCountry, setSelectedCountry]                     = useState();

  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    if (seekerId) {
      requestFetchSeeker("GET", `athletes/${seekerId}`);
      document.title = "Edit Athlete - Calevalab";
      requestSport("GET",`master/all?page=1&isActive=${true}`)
      requestCountry("GET",`country/all?page=1&isActive=${true}`)
      requestCCodes("GET",`country-codes/all`)
    }
  }, [seekerId]);

  useEffect(() => {
    if(responseSport && responseSport.status){
      setAllSports(responseSport.sports)
    }
  },[responseSport])

  useEffect(() => {
    if(responseCountry && responseCountry.status){
      setAllCountry(responseCountry.data)
    }
  },[responseCountry])

  useEffect(() => {
    if(responseCCodes && responseCCodes.status){
      setAllCountryCodes(responseCCodes.data)
    }
  },[responseCCodes])

  useEffect(() => {
    if (responseFetchUser) {
      const { firstName, lastName, email,organizationCode,other_fields } = responseFetchUser.user;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      setValue("organizationCode", organizationCode);
      setValue("height", (other_fields && other_fields.height)?other_fields.height:'');
      setValue("weight", (other_fields && other_fields.weight)?other_fields.weight:'');
      setValue("bmi", (other_fields && other_fields.bmi)?other_fields.bmi:'');
      setValue("sport", (other_fields && other_fields.sportName && other_fields.sportName._id)?other_fields.sportName._id:'');
      setValue("country", (other_fields && other_fields.country && other_fields.country._id)?other_fields.country._id:'');
      setValue("contact", (other_fields && other_fields.contact)?other_fields.contact:'');
      setValue("gender", (other_fields && other_fields.gender)?other_fields.gender:'');
      setValue("orientation", (other_fields && other_fields.orientation)?other_fields.orientation:'');
      setValue("dob", (other_fields && other_fields.dob)?moment(other_fields.dob).format("YYYY-MM-DD"):'');
      setValue("image", (other_fields && other_fields.profilePic)?other_fields.profilePic:'');
      console.log("id",(other_fields && other_fields.countryCode && other_fields.countryCode._id)?other_fields.countryCode._id:'')
      if(other_fields && other_fields.countryCode && other_fields.countryCode._id){
        setValue("countryCode", (other_fields && other_fields.countryCode && other_fields.countryCode._id)?other_fields.countryCode._id:'');
      }else{
        if(allCountryCodes && allCountryCodes.length>0){
          const selectedCodeObj = allCountryCodes.find(obj => {
            if(obj.dial_code == DefaultCountryCode.CountryCode){
              return obj;
            }
          })
          setValue('countryCode',selectedCodeObj?._id)
        }
      }
      setImage((other_fields && other_fields.profilePic)?other_fields.profilePic:'');
      setSelectedSport((other_fields && other_fields.sportName && other_fields.sportName._id)?other_fields.sportName._id:'')
      setSelectedCountry( (other_fields && other_fields.country && other_fields.country._id)?other_fields.country._id:'')

      setOldValues({
        firstName: firstName,
        lastName: lastName,
        email: email,
        organizationCode: organizationCode,
        height: other_fields?.height || '',
        weight: other_fields?.weight || '',
        bmi: other_fields?.bmi || '',
        sport: other_fields?.sportName?._id || '',
        country: other_fields?.country?._id || '',
        contact: other_fields?.contact || '',
        gender: other_fields?.gender || '',
        orientation: other_fields?.orientation || '',
        dob: other_fields?.dob ? moment(other_fields.dob).format("YYYY-MM-DD") : '',
        image: other_fields?.profilePic || '',
        countryCode: other_fields?.countryCode?._id || 
          (allCountryCodes?.length > 0 ? allCountryCodes.find(obj => obj.dial_code === DefaultCountryCode.CountryCode)?._id || '' : '')
      });
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Athlete has been updated successfully.");
      history.push("/athletes");
    }
  }, [response]);

  const handleWeight = (e,name) => {
    if(name == 'weight'){
      setValue('weight',e.target.value)
      const weight =   parseFloat(e.target.value)
      const height =  parseFloat( getValues('height'))
      if(weight > 0 && height > 0){	
        const finalBmi = weight/(height/100*height/100)
        setValue("bmi", parseFloat(finalBmi).toFixed(1));
        clearErrors("bmi")
      }else{
        setValue('bmi',"")
      }
      if(e.target.value){
        setError('weight','')
      }

      if(e.target.value == ""){
        setError('weight',{type:"required"})
      }else{
        if(e.target.value > 0  ){
          clearErrors("weight")
        }
        else {
          setError('weight',{type:"pattern"})
        }
      }
    }
  }

  const handleHeight = (e,name) => {

    if(name == "height"){
      setValue('height',e.target.value)
      const weight =  parseFloat(getValues('weight'));
      const height =  parseFloat(parseInt(e.target.value))
      if(weight > 0 && height > 0){	
        const finalBmi = weight/(height/100*height/100)
        setValue("bmi", parseFloat(finalBmi).toFixed(1));
        setError('bmi','')

      }else{
        setValue('bmi',"")
      }

      if(e.target.value == ""){
        setError('height',{type:"required"})
      }else{
        if(e.target.value > 0  ){
          clearErrors("height")
        }
        else {
          setError('height',{type:"pattern"})
        }
      }
    }
  }

  const handleDate = (e, name) => {
    if(name == 'dob'){
      setValue('dob',e.target.value)
      clearErrors("dob")
    }
  }
  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const typeArr = event.target.files[0].type.split("/");
      if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
        setError("image", {type:"manual"});
        setValue("image", event.target.files[0]);
        setDisplayImage("")
        setImage("");
        return;
      }
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      clearErrors("image")
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
  };

  const onSubmit = (data) => {
    const { firstName, lastName, gender,
      orientation,
      height,
      weight,
      bmi,
      sport,
      country,
      contact,
      dob,email,
      organizationCode, countryCode } = data;

    const { email: oldEmail } = responseFetchUser.user;
    let error = 0;

    let updates = {};

    if (email.trim() != oldEmail) {
      updates.email = email;
    }

    if(new Date(dob).getTime() > new Date(minDateOfBirth).getTime()){
      setError("dob",{type:"manualDOB"})
      error = 1;
    }
   
    let fd = new FormData();

    if(displayImage){
      if (data.image && data.image.length > 0) {
        if (data.image[0].type) {
          const typeArr =  data.image[0].type.split("/");
  
          if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
            setError("image", {
              type: "manual",
            });
            error = 1;
          }else{
              fd.append("file", data.image[0]);
          }
        }
      }
    }else{
      if(image){
          fd.append("old_image", image);
      }else{
        if (data.image && data.image.length > 0) {
          if (data.image[0].type) {
            const typeArr =  data.image[0].type.split("/");
    
            if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
              setError("image", {
                type: "manual",
              });
              error = 1;
            }else{
                fd.append("file", data.image[0]);
            }
          }
        }
      }
    }
  
    if(error == 1){
      return;
    }

    fd.append("firstName", firstName);
    fd.append("lastName", lastName);
    fd.append("gender", gender);
    fd.append("orientation", orientation);
    fd.append("organizationCode", organizationCode);
    fd.append("height", height);
    fd.append("weight", weight);
    fd.append("bmi", bmi);
    fd.append("sportName", sport);
    fd.append("country", country);
    fd.append("contact", contact);
    fd.append("dob", dob);
    fd.append("email", email);
    fd.append("userId", seekerId);
    fd.append("countryCode",countryCode);
  
    if(compareChanges(oldValues,data)){
      request("PUT", "athletes", fd);
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "firstName",
        registerFields: {
          required: true,
          // pattern: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
          pattern:/^[\S.]+(?:[\s.]+[\S.]+)*$/,
          maxLength:NAME_LIMIT,
          isNameField:true
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid first name.",
        },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "lastName",
        registerFields: {
          required: true,
          pattern:/^[\S.]+(?:[\s.]+[\S.]+)*$/,
          maxLength:NAME_LIMIT,
          isNameField:true
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid last name.",
        },
      },
      {
        Component: SelectInput,
        label: "Gender",
        name: "gender",
        registerFields: {
          required: true,
          isNoOnChange:true},
        children: (
          [
            { value: "", label: "Select Gender" },
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "other", label: "Other/Prefer Not To Say!" }
          ]
        ),
        control:control
      },
      {
        Component: SelectInput,
        label: "Orientation",
        name: "orientation",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        children: (
          [
            { value: "", label: "Select Orientation" },
            { value: "Left", label: "Left" },
            { value: "Right", label: "Right" },
          ]
        ),
        control:control
      },
      {
        Component: Input,
        label: "Height(cm)",
        type: "text",
        name: "height",
        registerFields: {
          required: true,
          pattern: /^\d{1,100}(,\d{3})*(\.\d+)?$/i,
          
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid input.",
        },
        handleChange:handleHeight,
        control:control
      },
      {
        Component: Input,
        label: "Weight(kg)",
        type: "text",
        name: "weight",
        registerFields: {
          required: true,
          pattern: /^\d{1,100}(,\d{3})*(\.\d+)?$/i,
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid input.",
        },
        handleChange:handleWeight,
        control:control
      },
      {
        Component: Input,
        label: "BMI",
        type: "text",
        name: "bmi",
        registerFields: {
          required: true,
        },
        disabled:true,
      },
      {
        Component: SelectInput,
        label: "Sport",
        name: "sport",
        registerFields: {required: true,
          isNoOnChange:true
        },
        children: (  
          [
            { value: "", label: "Select Sport" },  // Default option
            ...(allSports && allSports.length > 0
              ? allSports.map(obj => ({ value: obj._id, label: obj.name }))
              : []
            )
          ]
        ),
        control:control
      },
      {
        Component: SelectInput,
        label: "Country",
        name: "country",
        registerFields: {required: true,
          isNoOnChange:true
        },
        children: (
          [
            { value: "", label: "Select Country" },  // Default option
            ...(allCountry && allCountry.length > 0
              ? allCountry.map(obj => ({ value: obj._id, label: obj.name }))
              : []
            )
          ]
        ),
        control:control
      },
     
      {
        Component: SelectInput,
        label: "Country Code",
        name: "countryCode",
        colClass:"col-xl-2",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        extraClass:"countryCode-input",
        children: (
          allCountryCodes && allCountryCodes.length > 0 
          ? allCountryCodes.map(obj => ({
              value: obj._id,
              label: obj.dial_code,
              selected: obj.dial_code === DefaultCountryCode?.CountryCode
            }))
          : []
        ),
        control:control
      },

      {
        Component: Input,
        label: "Phone Number",
        type: "text",
        name: "contact",
        colClass:"col-xl-4",
        registerFields: {
          required: true,
          pattern: /^[0-9]{7,12}$/gm,
        },
        registerFieldsFeedback: {
          pattern: "Phone number must be minimum 7 and maximum 12 digit number.",
        },
      },
      {
        Component: Input,
        label: "Date Of Birth",
        type: "date",
        name: "dob",
        registerFields: {
          required: true,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid date of birth.",
        },
        isDate:true,
        max:moment().subtract(1, 'days').format("YYYY-MM-DD"),
        handleDateChange:handleDate,
        control:control
      },
      {
        Component: Input,
        label: "Profile Image",
        type: "file",
        name: "image",
        registerFields: {
          required: false,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid image extension type.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      {
        Component: Input,
        label: "Organization Code",
        type: "text",
        name: "organizationCode",
        registerFields: {
          required: false,
        }
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Athlete"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/athletes" /*backPageNum: page */ },
            name: "Back To Athletes",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Athlete
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
