import React, { useRef, useEffect } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from "../../ckeditor5/build/ckeditor";
import { CKEditor } from "ckeditor4-react";
import { Controller } from "react-hook-form";
import { API } from "../../constant/api";
import { Link } from "react-router-dom";
import Select from "react-select";


let currDate = new Date();
currDate = currDate.toISOString().split("T")[0];

const customStyle = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
};

export const Input = ({
  colClass,
  label,
  type,
  tooltip,
  name,
  min,
  max,
  errors,
  register,
  registerFields,
  inputData,
  otherRegisterFields,
  registerFieldsFeedback,
  children,
  isDate,
  control,
  handleDateChange,
  isMedia,
  handleMedia,
  accept,
  image,
  video,
  handleRemoveMedia,
  displayImage,
  displayVideo,
  media,
  disabled,
  handleChange,
  mediaClass
}) => {
  let [k, v] = name.split(".");
  let isKey = v ? (errors[k] ? errors[k][v] : errors[name]) : errors[name];
  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label>
          {label}{" "}
          {registerFields.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
          {tooltip?.show ? (
            <i className="fa fa-question-circle fa-1x" title={tooltip?.title} />
          ) : (
            ""
          )}
        </label>

        {isMedia && media == "imageLang" ? (
            <input
            type={type}
            {...register(
              name,
              image
                ? {
                    required: false,
                  }
                : registerFields
            )}
            onChange={(e) => handleMedia(e)}
            name={name}
            accept={accept}
            placeholder={label}
            id="image"
            className={`form-control form-control-solid form-control-lg ${
              isKey && "is-invalid"
            }`}
            {...inputData}
          />

        ): isMedia && media == "image" ? (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <input
                  type={type}
                  {...register(
                    name,
                    image
                      ? {
                          required: false,
                        }
                      : registerFields
                  )}
                  onChange={(e) => handleMedia(e)}
                  name={name}
                  accept={accept}
                  placeholder={label}
                  id="image"
                  className={`form-control form-control-solid form-control-lg ${
                    isKey && "is-invalid"
                  }`}
                  {...inputData}
                />
              )}
            />
          </> 
        ) : isMedia && media == "video" ? (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <input
                  type={type}
                  {...register(
                    name,
                    video
                      ? {
                          required: false,
                        }
                      : registerFields
                  )}
                  onChange={(e) => handleMedia(e)}
                  name={name}
                  id="video"
                  accept={accept}
                  placeholder={label}
                  className={`form-control form-control-solid form-control-lg ${
                    isKey && "is-invalid"
                  }`}
                  {...inputData}
                />
              )}
            />
          </>
        ) : isDate  && !registerFields.isNoOnChange ?  (
          <Controller
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <input
                type={type}
                {...register(name, registerFields)}
                onChange={(e) => handleDateChange(e, name)}
                name={name}
                min={min}
                max={max}
                placeholder={label}
                className={`form-control form-control-solid form-control-lg ${isKey &&  "is-invalid"}`}
                {...inputData}
              />
            )}
          />
        ) 
        : isDate &&  registerFields.isNoOnChange ? (
          <Controller
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <input
                type={type}
                {...register(name, registerFields)}
                name={name}
                min={min}
                max={max}
                placeholder={label}
                className={`form-control form-control-solid form-control-lg ${isKey &&  "is-invalid"}`}
                {...inputData}
              />
            )}
          />
        ) 
        : name == "height" || name == "weight" ||  name == "from0" || name == "to0" ||  name == "from1" || name == "to1" ||  name == "from2" || name == "to2" ||  name == "from3" || name == "to3" ? (
          <Controller
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <input
                type={type}
                {...register(name, registerFields)}
                onChange={(e) => handleChange(e, name)}
                name={name}
                placeholder={label}
                className={`form-control form-control-solid form-control-lg ${
                  isKey && "is-invalid"
                }`}
                {...inputData}
              />
            )}  
          /> 
        ):(
          <input
            type={type}
            className={`form-control form-control-solid form-control-lg ${
              isKey && "is-invalid"
            }`}
            name={name}
            min={min}
            placeholder={label}
            disabled={disabled == true ? true : false}
            {...register(name, registerFields)}
            {...inputData}
          />
        )}
        {registerFields?.required && isKey?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}

        {registerFields?.minLength && isKey?.type === "minLength" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is invalid.
          </div>
        )}
        {!registerFields?.isNameField && registerFields?.maxLength && isKey?.type === "maxLength" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is invalid.
          </div>
        )}
        {registerFields?.isNameField && registerFields?.maxLength && isKey?.type === "maxLength" && (
            <div className="invalid-feedback">
              The {label.toLowerCase()} should not exceed 50 characters.
            </div>
        )}
        {registerFields?.min >= 0 && isKey?.type === "min" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is invalid.
          </div>
        )}

        {registerFields?.pattern && isKey?.type === "pattern" && (
          <div className="invalid-feedback">
            {registerFieldsFeedback?.pattern}
          </div>
        )}

        {otherRegisterFields?.feedback && isKey?.type === "manual" && (
          <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
        )}

        {otherRegisterFields?.feedback && isKey?.type === "manualDOB" && (
          <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
        )}

        {displayImage ? (
          <div className="d-flex">
            <img className={`imageMedia ${mediaClass ?? ""}`} src={displayImage} alt="" />
            <Link to="#" onClick={handleRemoveMedia} className="mx-3">
              Remove
            </Link>
          </div>
        ) : image ? (
          <div className="d-flex">
            <img className={`imageMedia ${mediaClass ?? ""}`} src={`${API.PORT}/${image}`} />
            <Link to="#" onClick={handleRemoveMedia} className="mx-3">
              Remove
            </Link>
          </div>
        ) : (
          ""
        )}

        {displayVideo ? (
          <div className="d-flex">
            <video className="videoMedia" controls>
              <source src={`${displayVideo}`} type="video/mp4" />
            </video>
            <Link to="#" onClick={handleRemoveMedia} className="mx-3">
              Remove
            </Link>
          </div>
        ) : video ? (
          <div className="d-flex">
            <video className="videoMedia" controls>
              <source src={`${API.PORT}/${video}`} type="video/mp4" />
            </video>
            <Link to="#" onClick={handleRemoveMedia} className="mx-3">
              Remove
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      {children}
    </div>
  );
};

export const CKEditorInput = ({
  colClass,
  label,
  name,
  errors,
  registerFields,
  getValues,
  setValue,
  trigger,
  inputData,
  otherRegisterFields,
  clearErrors,
  isEdit,
  ckcontent,
  setError
}) => {
  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label className="text-capitalize">
          {label}{" "}
          {registerFields?.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>
        <div className={`${errors[name] && "is-invalid"}`}>
          {isEdit && ckcontent && (
            <CKEditor
              initData={getValues(name)}
              editorUrl="https://cdn.ckeditor.com/4.22.1/standard/ckeditor.js"
              config={{
                versionCheck: false,
                extraAllowedContent:
                  "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                allowedContent: true,
                protectedSource: [/<i[^>]*><\/i>/g],
                // removeEmpty: { i: false },
              }}
              onChange={({ editor }) => {
                const data = editor.getData();
                  setValue(name, data);
                  trigger(name);
                  clearErrors(name);
              }}
              {...inputData}
            />
          )}
          {!isEdit && (
            <CKEditor
              initData={getValues(name)}
              editorUrl="https://cdn.ckeditor.com/4.22.1/standard/ckeditor.js"
              config={{
                versionCheck: false,
                extraAllowedContent:
                  "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                allowedContent: true,
                protectedSource: [/<i[^>]*><\/i>/g],
                // removeEmpty: { i: false },
              }}
              onChange={({ editor }) => {
                const data = editor.getData();
                if (data) {
                  setValue(name, data);
                  trigger(name);
                  clearErrors(name);
                }
              }}
              {...inputData}
            />
          )}
        </div>

        {registerFields?.required && errors[name]?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
        {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
          <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
        )}
      </div>
    </div>
  );
};


export const SelectInput = ({
  colClass,
  label,
  name,
  errors,
  register,
  registerFields,
  children,
  onChange, 
  moreData,
  extraClass,
  control,
  disabled=false,
  handleChange = () => {}
}) => {
  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label>
          {label}{" "}
          {(registerFields.required) ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>
        {registerFields.isNoOnChange ?  (
          <Controller
            control={control}
            name={name}
            render={({
              field: { onChange,value},
            }) => (
          
              <Select
                name={name}
                options={children}
                value={children.find(option => option.value == value)}
                {...register(name, registerFields)}
                onChange={(selectedOption) => {
                  onChange(selectedOption.value);  // Update the form's state
                }}
                placeholder="Select " 
                className={`${errors[name] && "is-invalid"} ${extraClass ? extraClass:""}`}
                    styles={customStyle}
              /> 
            )} 
          />
        )
        : (
          <Controller
            control={control}
            name={name}
            render={({
              field: { onChange,value},
            }) => (
              <Select
                name={name}
                isDisabled={disabled}
                options={children}
                value={children.find(option => option.value == value)}
                {...register(name, registerFields)}
                onChange={(selectedOption) => {
                  onChange(selectedOption.value);
                  handleChange(selectedOption.value)
                }}
                placeholder="Select " 
                className={`${errors[name] && "is-invalid"} ${extraClass ? extraClass:""}`}
                styles={customStyle}
              />
            )}
          />
          )
        }
        {/* {registerFields.isNoOnChange ?  (
          <select
            name={name}
            className={`form-control form-control-solid form-control-lg ${
              errors[name] && "is-invalid"
            } ${extraClass ? extraClass:""}`}
            {...register(name, registerFields)}
          >
            {children}
          </select>
        )
        : (
          <select                 name={name}
                className={`form-control form-control-solid form-control-lg ${
                  errors[name] && "is-invalid"
                } ${extraClass ? extraClass:""}`}
                {...register(name, registerFields)}
                onChange={(e) => onChange && onChange(e.target.value)
                }
              >
                {children}
              </select>
          )
        } */}

        {registerFields.required && errors[name]?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
      </div>
      {moreData}
    </div>
  );
};

export const Textarea = ({
  colClass,
  label,
  name,
  errors,
  register,
  registerFields,
  inputData,
  placeholder,
}) => {
  let [k, v] = name.split(".");
  let isKey = v ? (errors[k] ? errors[k][v] : errors[name]) : errors[name];


  const refRegister = register(name, registerFields);

  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label>
          {label}{" "}
          {registerFields.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>
        <textarea
          className={`form-control form-control-solid form-control-lg ${
            isKey && "is-invalid"
          }`}
          name={name}
          cols="30"
          rows="5"
          placeholder={placeholder ?? `Enter ${name}`}
          // ref={inputData?.ref ?? null}
          // {...register(name, registerFields)}
          {...refRegister}
          ref={(e) => {
            refRegister.ref(e);
            // dobMonthInput.current = e;
            if (inputData?.ref) {
              inputData.ref.current = e;
            }
          }}
          // {...inputData}
        ></textarea>
        {registerFields?.required && isKey?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
      </div>
    </div>
  );
};

export const SearchInput = ({
  label,
  name,
  register,
  required,
  errors,
  isDate,
  clearErrors,
  otherRegisterFields,
  extras = {},
  isSelectInput,
  children,
}) => {
  const dateRef = useRef();

  const openDatePicker = () => {
    // dateRef.current.children;
  };

  return (
    <div className="col-lg-3 mb-lg-0 mb-6 mt-2">
      <label>{label}</label>

      {!isDate ? (
        !isSelectInput ? (
          <input
            type="text"
            placeholder={label}
            className={`form-control ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            {...extras}
          />
        ) : (
          <select
            name={name}
            className={`form-control ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            {...extras}
          >
            {children}
          </select>
        )
      ) : (
        <div ref={dateRef} onClick={openDatePicker}>
          <input
            type="date"
            placeholder={label}
            max={currDate}
            className={`form-control  ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            onChange={() => 
              clearErrors && clearErrors(errors[name])
            }
            {...extras}
          />
        </div>
      )}

      {required && errors[name]?.type === "required" && (
        <div className="invalid-feedback">
          The {label.toLowerCase()} field is required.
        </div>
      )}
      {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
        <div className="text-danger">{otherRegisterFields.feedback}</div>
      )}
       {otherRegisterFields?.manual && errors[name]?.type === "manual_other" && (
        <div className="text-danger">{errors[name]?.message}</div>
      )}
    </div>
  );
};

export const SearchInput2 = ({
  label,
  name,
  register,
  required,
  errors,
  isDate,
  otherRegisterFields,
  extras = {},
  isSelectInput,
  children,
  setValue,
  handleSearchObj,
  control,
}) => {
  const dateRef = useRef();

  const openDatePicker = () => {
    // dateRef.current.children;
  };

  return (
    <div className="col-lg-3 mb-lg-0 mb-6 mt-2">
      <label>{label}</label>

      {!isDate ? (
        !isSelectInput ? (
          <input
            type="text"
            placeholder={label}
            className={`form-control dropdown-toggle ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            onChange={(e) => {
              setValue(name,e.target.value)
              handleSearchObj(name,e.target.value)
            }}
            {...extras}
          />
        ) : (

          <Controller
            control={control}
            name={name}
            render={({
              field: { onChange},
            }) => (
              <Select
                name={name}
                options={children}
                {...register(name, { required })}
                onChange={(selectedOption) => {
                  setValue(name,selectedOption.value)
                  handleSearchObj(name,selectedOption.value)
                  onChange(selectedOption.value);  // Update the form's state
                }}
                placeholder="Select " 
                className={` ${errors[name] && "is-invalid"}`}
                    styles={customStyle}
              /> 
            )} 
          />
        //   <select
        //   name={name}
        //   className={`form-control dropdown-toggle ${errors[name] && "is-invalid"}`}
        //   {...register(name, { required })}
        //   {...extras}
        //   onChange={(e) => {
        //     setValue(name,e.target.value)
        //     handleSearchObj(name,e.target.value)
        //   }}
        // >
        //   {children}
        // </select>
        )
      ) : (
        <div ref={dateRef} onClick={openDatePicker}>
          <input
            type="date"
            placeholder={label}
            max={currDate}
            className={`form-control  ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            onChange={(e) => {
              setValue(name,e.target.value)
              handleSearchObj(name,e.target.value)
              // clearErrors && clearErrors(errors[name])
            }}
            {...extras}
          />
        </div>
      )}
      {required && errors[name]?.type === "required" && (
        <div className="invalid-feedback">
          The {label.toLowerCase()} field is required.
        </div>
      )}
      {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
        <div className="text-danger">{otherRegisterFields.feedback}</div>
      )}
      {otherRegisterFields?.manual && errors[name]?.type === "manual_other" && (
        <div className="text-danger">{errors[name]?.message}</div>
      )}
    </div>
  );
};

export const RenderInputFields = ({ InputFields, errors, register }) => {
  return (
    <>
      {InputFields.map((inputMain, index) => {
        return (
          <div key={index} className="row">
            {inputMain.map((InputSub, index) => {
              return (
                <InputSub.Component
                  key={index}
                  {...InputSub}
                  errors={errors}
                  register={register}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export const SubmitButton = ({ handleSubmit, onSubmit, name, pxClass }) => {
  return (
    <>
      <button
        onClick={handleSubmit(onSubmit)}
        style={{ display: "none" }}
      ></button>
      <div
        className={`d-flex justify-content-between border-top mt-5 pt-10 ${pxClass}`}
      >
        {/* px-10 */}
        <div className="mr-2">
            <button
              onClick={handleSubmit(onSubmit)}
              type="button"
              className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            >
            {name}
          </button>
        </div>
      </div>
    </>
  );
};

export const SearchSubmitButton = ({
  handleSubmit,
  onSearchHandler,
  onResetHandler,
  isDisableSearch
}) => {
  return (
    <>
      <button
        onClick={handleSubmit(onSearchHandler)}
        style={{ display: "none" }}
      ></button>
      <div className="row mt-8">
        <div className="col-lg-12">
          <button
            className="btn  btn-primary btn-primary--icon " 
            id="kt_search"
            onClick={handleSubmit(onSearchHandler)}
            disabled={isDisableSearch ? true : false}
          >
            <span>
              <span>Search</span>
              <i className="la la-search ml-1 pr-0"></i>
            </span>
          </button>
          &nbsp;&nbsp;
          <button
            className="btn btn-secondary btn-secondary--icon"
            id="kt_reset"
            data-toggle="collapse"
            data-target="#collapseOne6"
            onClick={onResetHandler}
          >
            <span>
              <i className="la la-close"></i>
              <span>Clear Search</span>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export const OtherInput = ({
  label,
  type,
  name,
  errors,
  register,
  registerFields,
  otherRegisterFields,
  patternError,
}) => {
  return (
    <div className="form-group">
      <input
        className={`form-control ${
          errors[name] && "is-invalid"
        } form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6`}
        type={type}
        name={name}
        autoComplete="off"
        placeholder={`Enter ${label}`}
        {...register(name, registerFields)}
      />
      {registerFields?.required && errors[name]?.type === "required" && (
        <div className="invalid-feedback">
          The {label.toLowerCase()} field is required.
        </div>
      )}
      {registerFields?.pattern && errors[name]?.type === "pattern" && (
        <div className="invalid-feedback">{patternError}</div>
      )}
      {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
        <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
      )}
    </div>
  );
};

export const SubTab = ({ name, index, image, onClick = () => {}  }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
        onClick={() => onClick(index)}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

export const SubInput = ({ index, errors, register, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};
export const RenderMultiLangInputFields = ({
  InputFields,
  errors,
  register,
  required,
  code,
  control,

}) => {
  return (
    <>
      {InputFields.map((inputMain, index) => {

        return (
          <div key={index} className="row">
            {inputMain.map((InputSub, idx) => {
              const registerFields = InputSub.registerFields || {};
              return (
                <InputSub.Component
                  key={idx}
                  {...InputSub}
                  errors={errors}
                  register={register}
                  registerFields={{
                    ...registerFields,
                    // required: registerFields?.req
                    //   typeof InputSub.isRequired === "boolean"
                    //     ? InputSub.isRequired
                    //     : required,
                  }}
                  name={InputSub.name + "-" + code}
                  code={code}
                  control={control}

                  // setValue={setValue}
                  // clearErrors={clearErrors}
                  // getValues={getValues}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};