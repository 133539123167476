import Login from "../pages/Login/Login";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";

import Dashboard from "../pages/Dashboard/Dashboard";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import Profile from "../components/Profile/Profile";

import ViewAllSettings from "../components/Settings/ViewAll";
import ViewPrefix from "../components/Settings/ViewPrefix";
import AddSetting from "../components/Settings/Add";
import EditSetting from "../components/Settings/Edit";
import ViewAllCms from "../components/Cms/ViewAll";
import ViewOneCms from "../components/Cms/NewViewOne";
import AddCms from "../components/Cms/NewAdd";
import EditCms from "../components/Cms/NewEdit";
import ViewAllEmailTemplate from "../components/EmailTemplate/ViewAll";
import AddEmailTemplate from "../components/EmailTemplate/Add";
import EditEmailTemplate from "../components/EmailTemplate/Edit";
import ViewAllEmailLogs from "../components/EmailLog/ViewAll";
import ViewAllAthletes from "../components/Athletes/ViewAll";
import AddAthlete from "../components/Athletes/Add";
import EditAthlete from "../components/Athletes/Edit";
import ViewAthlete from "../components/Athletes/ViewOne";
import ChangePasswordAthlete from "../components/Athletes/ChangePassword";
import ViewAllFaqs from "../components/Faq/ViewAll";
import AddFaq from "../components/Faq/Add";
import EditFaq from "../components/Faq/Edit";
import AddImage from "../components/Image/Add";
import ViewAllImage from "../components/Image/ViewAll";
import EditImage from "../components/Image/Edit";
import SeoPageAdd from "../components/SeoPage/Add";
import SeoPageViewAll from "../components/SeoPage/ViewAll";
import SeoPageEdit from "../components/SeoPage/Edit";

import HomeSliderAdd from "../components/Home/Slider/Add";
import HomeSliderViewAll from "../components/Home/Slider/ViewAll";
import HomeSliderEdit from "../components/Home/Slider/Edit";
import HomeUserGroupAdd from "../components/Home/UserGroup/Add";
import HomeUserGroupViewAll from "../components/Home/UserGroup/ViewAll";
import HomeUserGroupEdit from "../components/Home/UserGroup/Edit";
import HomeServiceDescriptionAdd from "../components/Home/ServiceDescriptions/Add";
import HomeServiceDescriptionViewAll from "../components/Home/ServiceDescriptions/ViewAll";
import HomeServiceDescriptionEdit from "../components/Home/ServiceDescriptions/Edit";
import HomeFeaturesAdd from "../components/Home/Features/Add";
import ViewAllNewsletterSubscriptions from "../components/NewsletterSubscriptions/ViewAll";
import ViewAllContactUs from "../components/ContactUs/ViewAll";
import ViewAllRequestDemo from "../components/RequestDemo/ViewAll";
import ViewAllSports from "../components/Sports/ViewAll";
import SportAdd from "../components/Sports/Add";
import SportEdit from "../components/Sports/Edit";

import ViewAllCoaches from "../components/Coaches/ViewAll";
import AddCoach from "../components/Coaches/Add";
import EditCoach from "../components/Coaches/Edit";
import ViewCoach from "../components/Coaches/ViewOne";
import ChangePasswordCoach from "../components/Coaches/ChangePassword";

import ViewAllClubs from "../components/Clubs/ViewAll";
import AddClub from "../components/Clubs/Add";
import EditClub from "../components/Clubs/Edit";
import ViewClub from "../components/Clubs/ViewOne";
import ChangePasswordClub from "../components/Clubs/ChangePassword";

import ViewAllPlans from "../components/Plan/ViewAll";
import AddPlan from "../components/Plan/Add";
import EditPlan from "../components/Plan/Edit";

import ViewAllSubscriptions from "../components/Subscription/ViewAll";
import AddSubscriptions from "../components/Subscription/Add";
import EditSubscriptions from "../components/Subscription/Edit";
import ViewAllRPEColorCoding from "../components/RPEColorCoding/ViewAll";
import ViewAllLanguage from "../components/LanguageSetting/ViewAll";
import AddLanguage from "../components/LanguageSetting/Add";
import EditLanguage from "../components/LanguageSetting/Edit";
import AddContactUsSetting from "../components/ContactUsSetting/Add";


export const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/setting", component: ViewAllSettings },
  { path: "/setting/prefix/:prefix", component: ViewPrefix },
  { path: "/setting/add", component: AddSetting },
  { path: "/setting/edit/:id", component: EditSetting },
  { path: "/cms", component: ViewAllCms },
  { path: "/cms/view/:id", component: ViewOneCms },
  { path: "/cms/add", component: AddCms },
  { path: "/cms/edit/:id", component: EditCms },
  { path: "/email-template", component: ViewAllEmailTemplate },
  { path: "/email-template/add", component: AddEmailTemplate },
  { path: "/email-template/edit/:id", component: EditEmailTemplate },
  { path: "/email-logs", component: ViewAllEmailLogs },
  { path: "/change-password", component: ChangePassword },
  { path: "/my-profile", component: Profile },
  { path: "/athletes", component: ViewAllAthletes },
  { path: "/athletes/add", component: AddAthlete },
  { path: "/athletes/edit/:id", component: EditAthlete },
  { path: "/athletes/view/:id", component: ViewAthlete },
  { path: "/athletes/change-password/:id", component: ChangePasswordAthlete },

  { path: "/coaches", component: ViewAllCoaches },
  { path: "/coach/add", component: AddCoach },
  { path: "/coach/edit/:id", component: EditCoach },
  { path: "/coach/view/:id", component: ViewCoach },
  { path: "/coach/change-password/:id", component: ChangePasswordCoach },

  { path: "/clubs", component: ViewAllClubs },
  { path: "/club/add", component: AddClub },
  { path: "/club/edit/:id", component: EditClub },
  { path: "/club/view/:id", component: ViewClub },
  { path: "/club/change-password/:id", component: ChangePasswordClub },

  { path: "/faq", component: ViewAllFaqs },
  { path: "/faq/add", component: AddFaq },
  { path: "/faq/edit/:id", component: EditFaq },

  { path: "/system-images/add", component: AddImage },
  { path: "/system-images", component: ViewAllImage },
  { path: "/system-images/edit/:id", component: EditImage },

  { path: "/seo-pages", component: SeoPageViewAll },
  { path: "/seo-page/add", component: SeoPageAdd },
  { path: "/seo-page/edit/:id", component: SeoPageEdit },

  { path: "/plans", component: ViewAllPlans },
  { path: "/plan/add", component: AddPlan },
  { path: "/plan/edit/:id", component: EditPlan },

  { path: "/subscriptions", component: ViewAllSubscriptions },
  { path: "/subscription/add", component: AddSubscriptions },
  { path: "/subscription/edit/:id", component: EditSubscriptions },

  { path: "/dashboard/slider", component: HomeSliderViewAll },
  { path: "/dashboard/slider/add", component: HomeSliderAdd },
  { path: "/dashboard/slider/edit/:id", component: HomeSliderEdit },

  { path: "/dashboard/tabs", component: HomeUserGroupViewAll },
  { path: "/dashboard/tab/add", component: HomeUserGroupAdd },
  { path: "/dashboard/tab/edit/:id", component: HomeUserGroupEdit },

  {
    path: "/dashboard/service-descriptions",
    component: HomeServiceDescriptionViewAll,
  },
  {
    path: "/dashboard/service-description/add",
    component: HomeServiceDescriptionAdd,
  },
  {
    path: "/dashboard/service-description/edit/:id",
    component: HomeServiceDescriptionEdit,
  },

  { path: "/dashboard/features", component: HomeFeaturesAdd },

  {
    path: "/newsletter-subscriptions",
    component: ViewAllNewsletterSubscriptions,
  },

  { path: "/contact-us", component: ViewAllContactUs },

  { path: "/request-for-demo", component: ViewAllRequestDemo },

  { path: "/sports", component: ViewAllSports },
  { path: "/sport/add", component: SportAdd },
  { path: "/sport/edit/:id", component: SportEdit },

  { path: "/rpe-color-codes", component: ViewAllRPEColorCoding },

  { path: "/language-setting", component: ViewAllLanguage },
  { path: "/language-setting/add", component: AddLanguage },
  { path: "/language-setting/edit/:id", component: EditLanguage },
  { path: "/contact-us-setting", component: AddContactUsSetting },
  
];

export const notPrivateRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
];
