import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput2, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, stringLimit } from "../../util/fn";
import { ArrowDown, ArrowUp } from "../../util/Svg";

const OBJ_TABLE = {
  "email": "email",
  "created on": "createdAt",
  status: "isActive",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  email = "",
  dateFrom = "1970-01-01",
  dateTo
) => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "createdAt";
    } 
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `newsletter-subscription/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&email=${email}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
};

const ViewAll = () => {
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const [isSearchToggleOpen, setIsSearchToggleOpen] = useState(false);

  const handleToggle = () => {
    const collapseElement = document.getElementById('collapseOne6');
    if (collapseElement) {
      collapseElement.classList.toggle('show'); 
    }
    setIsSearchToggleOpen(!isSearchToggleOpen);
  };

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
    setValue
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  const [searchObj, setSearchObj]                                 = useState({
    email:"",
    dateFrom:"",
    dateTo:""
  });

  const [isDisableSearch, setIsDisableSearch]                   = useState(true)

  const handleSearchObj = (name, value) => {
    if(name == 'dateTo'){
      if(new Date(value).getTime() > new Date().getTime()){
        setError("dateTo",{type:"manual",message: "manual"})
        return;
      }
      clearErrors(name);
    }
    if(name == 'dateFrom'){
      if(new Date(value).getTime() > new Date().getTime()){
        setError("dateFrom",{type:"manual",message: "manual"})
        return;
      }
     clearErrors(name);
    }
    
    let search = searchObj;
    search[name] = value;
 
    setSearchObj(search);
    if (search?.dateFrom && search?.dateTo) {
      if (Moment(search?.dateFrom).isAfter(search?.dateTo) && !Moment(search?.dateFrom).isSame(search?.dateTo, 'day')) {
          setError("dateTo", {
            type: "manual_other",
            message: "To Date cannot be smaller than From Date",
          });
          return;
      }
    }

    if(search?.email || search?.dateFrom || search?.dateTo){
      setIsDisableSearch(false);
    }else{
      setIsDisableSearch(true);
    }
  }

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Newsletter Subscriptions - Calevalab";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setRecords(response.data);
       setTotalDocuments((prev) =>
        response.totalDocuments !== undefined && response.totalDocuments !== null ? response.totalDocuments : prev
      );
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldRecords = [...records];
      const indexToChange = oldRecords.findIndex((r) => r._id == id);
      oldRecords[indexToChange].isActive = newStatus;

      setRecords(oldRecords);
      toast.success(responseChangeStatus.message);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newRecord = [...records];
      newRecord = newRecord.filter((r) => r._id != id);
      setRecords(newRecord);
      toast.success("Newsletter subscription has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setRecords([]);
    const { isActive, email, dateFrom, dateTo } =
      getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        email,
        dateFrom,
        dateTo
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "newsletter-subscription", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const { isActive, email, dateFrom, dateTo } =
      getValues();

     let error = 0;
    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo) && !Moment(dateFrom).isSame(dateTo, 'day')) {
        setError("dateTo", {
          type: "manual_other",
          message: "To Date cannot be smaller than From Date",
        });
        error = 1;
      }
    }
    if(new Date(dateTo).getTime() > new Date().getTime()){
      setError("dateTo",{type:"manual",message: "manual"})
      error = 1;
    }
    if(new Date(dateFrom).getTime() > new Date().getTime()){
      setError("dateFrom",{type:"manual"})
      error = 1;
    }
    if(error == 1){
      return;
    }

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("email");
    resetField("dateFrom");
    resetField("dateTo");
    setIsDisableSearch(true);
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { isActive, email, dateFrom, dateTo } =
      getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { isActive, email, dateFrom, dateTo } =
      getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
            page,
            perPage,
            sortBy,
            newOrder,
            isActive,
            email,
            dateFrom,
            dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          email,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const data = records.find((r) => r._id == id);
    const status = data.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "newsletter-subscription/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };


  const InputFields = [
    // {
    //   label: "Status",
    //   name: "isActive",
    //   required: false,
    // },
    {
      label: "Email",
      name: "email",
      required: false,
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback:  "From Date cannot be greater than Current Date",
      },
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "To Date cannot be greater than Current Date",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Newsletter Subscriptions" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                  <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      onClick={handleToggle}
                    >
                      Search {isSearchToggleOpen ? <ArrowUp/> : <ArrowDown/>}
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput2
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                handleSearchObj={handleSearchObj}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                            isDisableSearch={isDisableSearch}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={records}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                        },
                        { isLink: false, name: "Delete", click: deleteHandler },
                        // {
                        //   isLink: false,
                        //   name: "Login",
                        //   click: loginUser,
                        //   title: "Login",
                        // },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={records.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
