import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import {
  Input,
  CKEditorInput,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  SubTab, SubInput
} from "../Form/Form";
import { compareChanges } from "../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../util/other";

const Edit = (props) => {
  const { id: emailTemplateId } = props.match.params;
  const { languages } = useSelector((state) => state.setting);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
    control
  } = useForm();

  const ckEditorRef = useRef();

  const [emailConstants, setEmailConstants] = useState([]);
  const [ckcontent, setCkContent]           = useState()
  const [currentTab, setCurrentTab]         = useState("en");
  const { response, request } = useRequest();

  const { response: responseFetchTemplate, request: requestFetchTemplate } =
    useRequest();

  const history = useHistory();
  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    requestFetchTemplate("GET", `email-template/${emailTemplateId}`);
    document.title = "Edit Email Template - Calevalab";
  }, []);

  useEffect(() => {
    ckEditorRef.current = {};
    if (languages) {
      languages.forEach((lang) => {
        register(`body-${lang.code}`, { required: true });
        ckEditorRef.current[lang.code] = null;
      });
    }
  }, [languages]);

  useEffect(() => {
    if (responseFetchTemplate) {
      const { constants, name, langData } = responseFetchTemplate.emailTemplate[0];
      const subData = {};
      if (langData?.length > 0) {
        langData.forEach((lang) => {
          

          const code = lang?.languageCode;
          subData["body-" + code] = lang?.body;
          subData["subject-" + code] = lang?.subject;
          subData["id-" + code] = lang?._id;
        });
      
     
      }
      reset({ ...subData, name });
      setEmailConstants(constants);
      setCkContent(true);

      const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
      const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
      setOldValues({
        langData:langDataShortedOld
      });
    }
  }, [responseFetchTemplate]);

  useEffect(() => {
    if (response) {
      toast.success("Email Template has been updated successfully.");
      history.push("/email-template");
    }
  }, [response]);

  const insertConstantHandler = () => {
    const constant = getValues("constant");
    if (constant.length == 0) {
      return;
    }
    ckEditorRef.current[currentTab].editor.insertText(`{${constant}}`);
  };

  const onSubmit = (data) => {
 
    const dataToSend = [], defaultData = {};
    let error = [];

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;
      dataToSend.push({
        languageCode: code,
        body: data["body-" + code] ?? "",
        subject: data["subject-" + code]?? "",
        id: data[`id-${code}`] ?? "",
      });
      if (languages[i].default) {
        defaultData.name = data["name"];
        defaultData.action = data["action"];
        defaultData.body = data["body-" + code];
        defaultData.subject = data["subject-" + code];
      }

       if (data["body-" + code].length === 0) {
          setError(`body-${code}`, {
            type: "manual",
          });
          error.push(true);
        }
    }

    if(error.some(err => err === true)){
      return;
    }

    const langDataWithoutId = dataToSend.map(({ id: _, ...rest }) => rest);
    const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));

    let newData = {
      
      langData:langDataShorted
    };

    if(compareChanges(oldValues,newData)){
      request("PUT", "email-template", {...defaultData, id:emailTemplateId, langData:dataToSend});
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
   
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          disabled: true,
        },
      },
      {
        Component: SelectInput,
        label: "Constants",
        name: "constant",
        registerFields: {},
        children: (
          [
            { value: "", label: "Select Constant" },  // Default option
            ...(emailConstants && emailConstants.length > 0
              ? emailConstants.map((constant, index) => ({ value: constant, key: index ,label:constant}))
              : []
            )
          ]
          // <>
          //   <option value="">{"Select Constant"}</option>
          //   {emailConstants.length > 0 &&
          //     emailConstants.map((constant, index) => (
          //       <option key={index} value={constant}>
          //         {constant}
          //       </option>
          //     ))}
          // </>
        ),
        control:control,
        moreData: (
          <button
            onClick={insertConstantHandler}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
          >
            Insert
          </button>
        ),
      },
    ],
  ];

  const handleCurrentTab = (code) => {
    setCurrentTab(code);
  }
  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Email Template"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/email-template", name: "Back To Email Template" },
        ]}
      />
      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Update Email Template
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                  <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                  onClick={() => handleCurrentTab(lang?.code)}
                                />
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                        {languages.length > 0 &&
                            languages.map((lang, index) => {
                              return(
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  InputFields={[
                                    [
                                      {
                                        Component: Input,
                                        label: "Subject",
                                        type: "text",
                                        name: "subject",
                                        registerFields: {
                                          required: true,
                                        },
                                      },
                                    ],
                                    [{
                                      Component: CKEditorInput,
                                      colClass: "col-xl-12",
                                      label: "Email Body",
                                      name: "body",
                                      registerFields: {
                                        required: true,
                                      },
                                      getValues,
                                      setValue,
                                      trigger,
                                      otherRegisterFields: {
                                        manual: true,
                                        feedback: "Email Body is required",
                                      },
                                      inputData: {
                                        onInstanceReady: (editor) => {
                                          ckEditorRef.current[lang.code] = editor;
                                        },
                                      },
                                      clearErrors,
                                          isEdit: true,
                                          ckcontent:ckcontent
                                    }]]
                                  }
                                  code={lang.code}
                                />
                              )
                            })}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                  
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
