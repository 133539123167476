import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput2, SearchSubmitButton } from "../Form/Form";
import { ArrowDown, ArrowUp } from "../../util/Svg";

const OBJ_TABLE = {
  name: "name",
  "accounts count": "accountsCount",
  price: "price",
  "created on": "createdAt",
  status: "isActive",
};

const searchQueryHandler = (page, per_page, sortBy, order, name = "") => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "createdAt";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    }
  } else {
    sortBy = "createdAt";
  }

  order = order.length > 0 ? order : "desc";

  return `plan/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&name=${name}`;
};

const ViewAll = () => {
  const [plans, setPlans] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const [isSearchToggleOpen, setIsSearchToggleOpen] = useState(false);

  const handleToggle = () => {
    const collapseElement = document.getElementById('collapseOne6');
    if (collapseElement) {
      collapseElement.classList.toggle('show'); 
    }
    setIsSearchToggleOpen(!isSearchToggleOpen);
  };

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    setValue
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  const [searchObj, setSearchObj]                                 = useState({
    name:"",
   
  });
  
  const [isDisableSearch, setIsDisableSearch]                   = useState(true)
  
  const handleSearchObj = (name, value) => {
    let search = searchObj;
    search[name] = value;
  
    setSearchObj(search);
  
    if(search?.name){
      setIsDisableSearch(false);
    }else{
      setIsDisableSearch(true);
    }
  }
  

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Plans - Calevalab";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setPlans(response.plans);
       setTotalDocuments((prev) =>
        response.totalDocuments !== undefined && response.totalDocuments !== null ? response.totalDocuments : prev
      );
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, status } = responseChangeStatus;

      const newPlans = [...plans];
      const indexToChange = newPlans.findIndex((plan) => plan._id == id);
      newPlans[indexToChange].isActive = status;

      setPlans(newPlans);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newPlans = [...plans];
      newPlans = newPlans.filter((plan) => plan._id != id);
      setPlans(newPlans);
      toast.success("Plan has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setPlans([]);
    const { name } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "plan", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const { name } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("name");
    setIsDisableSearch(true);
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { name } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        name
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { name } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request("GET", searchQueryHandler(page, perPage, sortBy, newOrder, name));
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request("GET", searchQueryHandler(page, perPage, sortBy, "desc", name));
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const planNew = plans.find((plan) => plan._id == id);
    const status = (planNew.isActive == "true" || planNew.isActive == true) ? "false" : "true";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "plan/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const InputFields = [
    {
      label: "Name",
      name: "name",
      required: true,
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Plans" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                  <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      onClick={handleToggle}
                    >
                      Search {isSearchToggleOpen ? <ArrowUp/> : <ArrowDown/>}
                    </a>

                    <Link to="/plan/add" className="btn btn-primary">
                      Add New plan
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput2
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                handleSearchObj={handleSearchObj}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                            isDisableSearch={isDisableSearch}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={plans}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                        },
                        {
                          isLink: true,
                          to: "/plan/edit",
                          name: "Edit",
                          extraData: true,
                        },
                        { isLink: false, name: "Delete", click: deleteHandler },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      dontShowSort={[]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={plans.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
