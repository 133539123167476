import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";

import {
  Input,
  RenderInputFields,
  SubmitButton, SubTab, SubInput
} from "../../Form/Form";
import { API } from "../../../constant/api";
import { useSelector } from "react-redux";
import { compareChanges } from "../../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../../util/other";

const Edit = (props) => {
  const { id: recordId } = props.match.params;
  const ckEditorRef = useRef();
  const history = useHistory();
  const { languages } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    getValues,
    trigger,
    clearErrors,
    reset
  } = useForm();

  const { response: responseData, request: requestData } = useRequest();
  const { response, request } = useRequest();

  const [image, setImage] = useState();
  const [displayImage, setDisplayImage] = useState();
  const [images, setImages]                                              =  useState(null);

  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    if (recordId) {
      requestData("GET", `home-slider/${recordId}`);
      document.title = "Edit Slider - Calevalab";
    }
  }, [recordId]);

  useEffect(() => {
    if (responseData) {
      const { title,subTitle, image, link, langData } = responseData.data;
      
      const subData  =  {};

      if(langData?.length>0){
        langData.forEach((lang) => {
          const code = lang?.languageCode;
          subData["subTitle-" + code] = lang?.subTitle;
          subData["title-" + code] = lang?.title;
          // subData["link-" + code] = lang?.link;
          subData["id-" + code] = lang?._id;
        });
        const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
      const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
      setOldValues({
        image: image,
        link: link,
        langData:langDataShortedOld
      });
        reset({ ...subData });
      }

      setValue("title", title);
      setValue("subTitle", subTitle);
      setValue("link", link);
      setValue("image", image);
      setImage(image);
    }
  }, [responseData]);

  

  useEffect(() => {
    if (response) {
      toast.success("Slider has been updated successfully.");
      history.push("/dashboard/slider");
    }
  }, [response]);

  const onSubmit = (data) => {

    const langData                     = [];
    let fd                              = new FormData();

    if(data?.image?.length > 0 && data?.image[0]?.type){
      fd.append("file", data.image[0]);
    }else if(image){
      fd.append("old_image", image);
    }
    
    fd.append("link", data?.link);

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;

      if (languages[i].default) {
        fd.append("title",  data[`title-${code}`]);
        fd.append("subTitle",  data[`subTitle-${code}`]);
        // fd.append("link", data[`link-${code}`]);
      }
      langData.push({ id: data[`id-${code}`], languageCode:code, title: data[`title-${code}`], subTitle: data[`subTitle-${code}`]}); 
    }
    const langDataWithoutId = langData.map(({ id: _, ...rest }) => rest);
    const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));

    let newData = {
      image: (data?.image?.length > 0 && data?.image[0]?.type) ? data.image[0] : image,
      link: data?.link,
      langData:langDataShorted
    };
    if(compareChanges(oldValues,newData)){
      fd.append("langData", JSON.stringify(langData));
      fd.append("id", recordId);

      request("PUT", "home-slider", fd);
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
  };

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setError("image", "");
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
  };

  const InputFields = [
    {
        Component: Input,
        label: "Sub Title Name",
        type: "text",
        name: "subTitle",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Sub title name can only contain letters.",
        // },
    },
    {
        Component: Input,
        label: "Title Name",
        type: "text",
        name: "title",
        registerFields: {
          required: true,
        }
      }
  ];

  const InputFields2 = [
    [
      {
        Component: Input,
        label: "Link",
        type: "text",
        name: "link",
        registerFields: {
          required: true,
        },
      },

      {
        Component: Input,
        label: "Banner Image",
        type: "file",
        name: "image",
        registerFields: {
          required: true,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Slider"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/dashboard/slider" /*backPageNum: page */ },
            name: "Back To Sliders",
          },
        ]}
      />
      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Slider
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row"></div>
                  <div className="card card-custom gutter-b">
                    <div className="card-header card-header-tabs-line">
                      <div className="card-toolbar">
                        <ul
                          className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                          role="tablist"
                        >
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <SubTab
                                key={index}
                                name={lang.name}
                                index={index}
                                image={lang?.image}
                              />
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="tab-content px-10">
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <>
                              <SubInput
                                key={index}
                                index={index}
                                errors={errors}
                                register={register}
                                //   required={lang.default}
                                required={true}
                                // InputFields={InputFields}
                                code={lang.code}
                                InputFields={[
                                  InputFields.map((ip) => ({
                                    ...ip,
                                    // required:ip?.name === "image" ? false:true,
                                    children: ip?.name === "image" && images?.[lang.code] && (
                                      <img
                                        src={`${API.PORT}/${images?.[lang.code]}`}
                                        width={100}
                                        height={100}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        data-fancybox
                                      />
                                    ),
                                  })),
                                ]}
                              />
                            </>
                          ))}
                      </div>
                      <div className="row"></div>
                    </div>
                  </div>
                  <div className="row"></div>
    
                  <RenderInputFields
                    InputFields={InputFields2}
                    errors={errors}
                    register={register}
                  />

                  <SubmitButton
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    name="Update"
                  />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
