import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Table from "../Table/Table";

const OBJ_TABLE = {
  "Club Name": "clubName",
  Role: "role",
  Status: "status",
  "Added At": "addedAt",
};

const ViewAll = ({ userData }) => {
  const [seekers, setSeekers] = useState([]);

  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });

  const { records_per_page } = useSelector((state) => state.setting);
  const { request, response } = useRequest();

  useEffect(() => {
    if (response) {
      setSeekers(response.data);
       setTotalDocuments((prev) =>
        response.totalDocuments !== undefined && response.totalDocuments !== null ? response.totalDocuments : prev
      );
    }
  }, [response]);


  
  if(userData.length > 0){
    return (
      <div className="dataTables_wrapper ">
      <Table
        currentSort={currentSort}
        // sortingHandler={sortingHandler}
        mainData={userData}
        tableHeading={Object.keys(OBJ_TABLE)}
        tableData={Object.values(OBJ_TABLE)}
        onlyDate={{
          createdAt: "date",
          startDate: "dateTime",
          endDate: "dateTime",
          addedAt: "dateTime",
        }}
      />

      {perPage !== 0 && (
        <Pagination
          page={page}
          totalDocuments={totalDocuments}
          // getNewData={fetchMoreData}
          perPage={perPage}
          defaultPerPage={records_per_page}
          // perPageChangeHandler={perPageChangeHandler}
          currentDocLength={seekers.length}
        />
      )}
    </div>
  );

  }
 
  return "No Roles Found";

};

export default ViewAll;
