export function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  let d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

export const addOneToDate = (currDate) => {
  currDate.setDate(currDate.getDate() + 1);
  return (currDate = currDate.toISOString().split("T")[0]);
};

export const stringLimit = (string) => {
  if (string.length >= 15) {
      return (
          string.substring(0, 15) + '...'
      )
  } else {
      return (
          string.substring(0, 15)
      )
  }
}

export const compareChanges = (oldValue, data, keysToIgnore = []) => {
  const fieldsChanged = Object.keys(data).some((key) => {
    if (keysToIgnore.includes(key)) return  false;
    return !isEqual(data[key], oldValue[key]);
  });
 
  if (!fieldsChanged) {
    return false;
  }
  return true;
};

const isEqual = (value1, value2) => {

  // console.log(value1,value2,'internalStatus');
  if (value1 === value2) return true;
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    for (let i = 0; i < value1.length; i++) {
      if (!isEqual(value1[i], value2[i])) return false;
    }
    return true;
  }

  if (typeof value1 === 'object' && typeof value2 === 'object') {
    if (value1 === null || value2 === null) return value1 === value2;
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (!isEqual(value1[key], value2[key])) return false;
    }
    return true;
  }
  return false;
};