import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { Input, RenderInputFields, SubmitButton , 
  SubTab, SubInput} from "../Form/Form";
import { CHANGES_REQ_MESSAGE } from "../../util/other";
import { compareChanges } from "../../util/fn";



const Edit = (props) => {
  const { id: recordId } = props.match.params;
  const history = useHistory();
  const { languages } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();

  const { response: responseData, request: requestData }    = useRequest();
  const { response, request }                                           = useRequest();
  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    if (recordId) {
        requestData("GET", `master/sport/${recordId}`);
      document.title = "Edit Sport - Calevalab";
    }
  }, [recordId]);

  useEffect(() => {
    if (responseData) {
      const { name ,langData} = responseData.sport;
      const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
      const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
      setOldValues({
        langData:langDataShortedOld
      });
      const subData = {};
      if (langData?.length > 0) {
        langData.forEach((lang) => {
          const code = lang?.languageCode;
          subData["name-" + code] = lang?.name;
          subData["id-" + code] = lang?._id;
        });
        reset({ ...subData });
      }
      setValue("name", name);
    }
  }, [responseData]);

  useEffect(() => {
    if (response) {
      toast.success("Sport has been updated successfully.");
      history.push("/sports");
    }
  }, [response]);

  const onSubmit = (data) => {
    
    const dataToSend = [];
    const defaultData = {};

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;
      dataToSend.push({
        languageCode: code,
        name: data[`name-${code}`] ?? "",
        id: data[`id-${code}`] ?? "",
      });

      if (languages[i].default) {
        defaultData.name = data[`name-${code}`];
      }
    }
    const langDataWithoutId = dataToSend.map(({ id: _, ...rest }) => rest);
    const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
    let newData = {
      langData:langDataShorted
    };
    if(compareChanges(oldValues,newData)){
      request("PUT", "master/sport", {...defaultData, id:recordId, langData:JSON.stringify(dataToSend)});
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
    
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          // pattern: /^(?!\s*$)[A-Za-z ]+$/,
          // pattern:/^[\S.]+(?:[A-Za-z\s.]+[\S.]+)*$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Name can only contain letters and not starts and ends with a space."
        // },
      }
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Sport"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/sports" /*backPageNum: page */ },
            name: "Back To Sports",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Sport
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                />
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <>
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  // InputFields={InputFields}
                                  code={lang.code}
                                  InputFields={InputFields}
                                />
                              </>
                            ))}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
