import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";


import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
  SubTab, SubInput
} from "../Form/Form";
import { compareChanges } from "../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../util/other";



const Edit = (props) => {
  const { id: faqId } = props.match.params;
  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const ckEditorRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { response: responseFetchFaq, request: requestFetchFaq } = useRequest();
  const [oldValues, setOldValues]                     = useState([]);
  const { response, request } = useRequest();
  const [images, setImages]                                              =  useState(null);
  useEffect(() => {
    if (faqId) {
      requestFetchFaq("GET", `faq/${faqId}`);
      document.title = "Edit Faq - Calevalab";
    }
  }, [faqId]);

  useEffect(() => {
    if (responseFetchFaq) {
      const {langData } = responseFetchFaq.faq;
      const subData   = {};

      if(langData?.length>0){
        langData.forEach((lang) => {
          const code = lang?.languageCode;
          subData["question-" + code] = lang?.question;
          subData["answer-" + code] = lang?.answer;
          subData["id-" + code] = lang?._id;
        });
        const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
        const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
        setOldValues({
          langData:langDataShortedOld
        });
        reset({ ...subData });
      }

    }
  }, [responseFetchFaq]);

  useEffect(() => {
    if (response) {
      toast.success("FAQ has been updated successfully.");
      history.push("/faq");
    }
  }, [response]);

  const onSubmit = (data) => {

    const langData                      = [];
    const defaultData                   = {};

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;

      if (languages[i].default) {
        defaultData.question = data?.["question-" + code];
        defaultData.answer = data?.["answer-" + code];
      }

      langData.push({ id: data[`id-${code}`], languageCode:code, question: data[`question-${code}`], answer: data[`answer-${code}`]}); 
    }
    const langDataWithoutId = langData.map(({ id: _, ...rest }) => rest);
    const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));

    let newData = {
     
      langData:langDataShorted
    };
    if(compareChanges(oldValues,newData)){
      request("PUT", "faq", {
        ...defaultData,
        langData:JSON.stringify(langData),
        id: faqId,
      });
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
      };

  const InputFields = 
    [
      {
        Component: Input,
        type: "text",
        label: "Question",
        name: "question",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Textarea,
        label: "Answer",
        name: "answer",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
    ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Faq"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/faq" },
            name: "Back To FAQs",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">Edit Faq</h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row"></div>
                  <div className="card card-custom gutter-b">
                    <div className="card-header card-header-tabs-line">
                      <div className="card-toolbar">
                        <ul
                          className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                          role="tablist"
                        >
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <SubTab
                                key={index}
                                name={lang.name}
                                index={index}
                                image={lang?.image}
                              />
                            ))}
                        </ul>
                      </div>
                    </div>

                    <div className="card-body px-0">
                      <div className="tab-content px-10">
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <>
                              <SubInput
                                key={index}
                                index={index}
                                errors={errors}
                                register={register}
                                //   required={lang.default}
                                required={true}
                                // InputFields={InputFields}
                                code={lang.code}

                                InputFields={[
                                  InputFields.map((ip) => ({
                                    ...ip,
                                    // required:ip?.name === "image" ? false:true,
                                    children: ip?.name === "image" && images?.[lang.code] && (
                                      <img
                                        src={`${API.PORT}/${images?.[lang.code]}`}
                                        width={100}
                                        height={100}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        data-fancybox
                                      />
                                    ),
                                  })),
                                ]}
                              />
                            </>
                          ))}
                      </div>
                      <div className="row"></div>
                    </div>
                  </div>

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
