import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton, SubTab, SubInput } from "../../Form/Form";
import { useSelector } from "react-redux";
import { API } from "../../../constant/api";
import { compareChanges } from "../../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../../util/other";

const Edit = (props) => {
  const { id: recordId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    reset
  } = useForm();
  const { languages } = useSelector((state) => state.setting);
  const { response: responseData, request: requestData } = useRequest();
  const { response, request } = useRequest();
  const [images, setImages] = useState(null);
  const [image, setImage] = useState();
  const [displayImage, setDisplayImage] = useState();
  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    if (recordId) {
      requestData("GET", `home-user-group/${recordId}`);
      document.title = "Edit Tab - Calevalab";
    }
  }, [recordId]);

  useEffect(() => {
    if (responseData) {
      const { title, image, langData } = responseData.data;
      const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
      const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
      setOldValues({
        image: image,
        langData:langDataShortedOld
      });
      const subData = {};
      if (langData?.length > 0) {
        langData.forEach((lang) => {
          const code = lang?.languageCode;
          subData["title-" + code] = lang?.title;
          subData["id-" + code] = lang?._id;
        });
        reset({ ...subData });
      }
      setValue("title", title);
      setValue("image", image);
      setImage(image)
    }
  }, [responseData]);

  useEffect(() => {
    if (response) {
      toast.success("Tab has been updated successfully.");
       history.push("/dashboard/tabs");
    }
  }, [response]);

  const onSubmit = (data) => {

    const langData = [];
    let fd = new FormData();

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;
      if (languages[i].default) {
        fd.append("title", data[`title-${code}`]);
      }
      langData.push({ id: data[`id-${code}`], languageCode: code, title: data[`title-${code}`] });
    }
    if (displayImage) {
      if (data.image && data.image.length > 0) {
        fd.append("file", data.image[0]);
      }
    } else {
      if (image) {
        fd.append("old_image", image);
      }
    }
    const langDataWithoutId = langData.map(({ id: _, ...rest }) => rest);
    const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));

    let newData = {
      image: (data?.image?.length > 0 && data?.image[0]?.type) ? data.image[0] : image,
      langData:langDataShorted
    };

    if(compareChanges(oldValues,newData)){
          fd.append("langData", JSON.stringify(langData));
           fd.append("id", recordId);
         request("PUT", "home-user-group", fd);
        }
        else {
          toast.error(CHANGES_REQ_MESSAGE);
        }
  };

  const handleImage = (event) => {

    event.preventDefault();
    if (event.target.files[0]) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]))
      setError('image', '');
    } else {
      setDisplayImage('');
      setImage('');
      setValue('image', '')
    }
  }

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
  }

  const InputFields = [
    [
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Title can only contain letters.",
        // },
      }
    ],
  ];

  const InputFieldsImage = [
    [
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "image",
        registerFields: {
          required: true,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: 'image',
        mediaClass:"image-tab-media"
      }
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Tab"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/dashboard/tabs" /*backPageNum: page */ },
            name: "Back To Tabs",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Tab
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                />
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <>
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  // InputFields={InputFields}
                                  code={lang.code}
                                  InputFields={InputFields}
                                />
                              </>
                            ))}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                   
                    <RenderInputFields
                      InputFields={InputFieldsImage}
                      errors={errors}
                      register={register}
                    />
                  
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
