import React, { useEffect,useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../Form/Form";
import { compareChanges } from "../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../util/other";


const Edit = (props) => {
  const { id: planId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { response: responseFetchPlan, request: requestFetchPlan } =
    useRequest();

  const { response, request } = useRequest();
  const [oldValues, setOldValues]                     = useState([]);
  useEffect(() => {
    if (planId) {
      requestFetchPlan("GET", `plan/${planId}`);
      document.title = "Edit Plan - Calevalab";
    }
  }, [planId]);

  useEffect(() => {
    if (responseFetchPlan) {
      const { name, accountsCount, price } = responseFetchPlan.plan;

      setOldValues({
        name: name,
        accountsCount: accountsCount,
        price: price
       });
      reset({ name, accountsCount, price });
    }
  }, [responseFetchPlan]);

  useEffect(() => {
    if (response) {
      toast.success("Plan has been updated successfully.");
      history.push("/Plans");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { name, accountsCount, price } = data;

    if (accountsCount === 0) {
      setError("accountsCount", {
        type: "manual",
      });
      return;
    }

    if(compareChanges(oldValues,data)){
      request("PUT", "plan", {
        name,
        accountsCount,
        price,
        id: planId,
      });
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }

   
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Name",
        name: "name",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        type: "text",
        label: "Accounts Count",
        name: "accountsCount",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          pattern: /^[0-9]*$/,
        },
        registerFieldsFeedback: {
          pattern: "Accounts Count can only contains numbers.",
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Accounts count can not be zero",
        },
      },
      {
        Component: Input,
        type: "text",
        label: "Price",
        name: "price",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          pattern: /^[0-9]*$/,
        },
        registerFieldsFeedback: {
          pattern: "Price can only contains numbers.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Plan"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/plans" },
            name: "Back To Plans",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Plan
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
