import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import {  useHistory, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput2, SearchSubmitButton } from "../Form/Form"; 
import { addOneToDate} from "../../util/fn";
import { ArrowDown, ArrowUp } from "../../util/Svg";
import { CHANGES_REQ_MESSAGE, DefaultCountryCode ,NAME_LIMIT } from "../../util/other";
import { compareChanges } from "../../util/fn";

const OBJ_TABLE = {
  name: "name",
  email: "email",
  "contact no.": "contactNo",
  Date: "createdAt",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  name = "",
  email = "",
  contactNo = "",
  dateFrom = "1970-01-01",
  dateTo
) => {
  sortBy =
    sortBy.length > 0
      ? sortBy === "Date"
        ? "createdAt"
        : sortBy
      : "createdAt";
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `contact?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&name=${name}&email=${email}&contactNo=${contactNo}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
};

const ViewAll = () => {

  const [emailLog, setEmailLog] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({});
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Date",
    order: "desc",
  });
  const history = useHistory();
  const { location } = history;
  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
    setValue
  } = useForm();

  const { request, response } = useRequest();

  useEffect(() => {
    const query = location?.search;
    if (query) {
      let obj = {
        page: 1,
        per_page: 10,
        sortBy: "createdAt",
        order: "dec",
        name: "",
        email : "",
        contactNo : "",
        dateFrom: "1970-01-01",
        dateTo: addOneToDate(new Date()),
        
      };

      request(
        "GET",
        searchQueryHandler(
          obj.page,
          obj.per_page,
          obj.sortBy,
          obj.order,
          obj.name,
          obj.email,
          obj.contactNo,
          obj.dateFrom,
          obj.dateTo
        )
      );
      setPerPage(obj.per_page);
    } else if(records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }

    document.title = `${"Contact Us Logs"} - Calevalab`;
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setEmailLog(response.data);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setEmailLog([]);
    const { name, email, contactNo, dateFrom, dateTo } = getValues;

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        email,
        contactNo,
        dateFrom,
        dateTo
      )
    );
  };

  const [searchObj, setSearchObj]                                 = useState({
    name:"",
    email:"",
    dateFrom:"",
    dateTo:""
  });

  const [isSearchToggleOpen, setIsSearchToggleOpen] = useState(false);

  const handleToggle = () => {
    const collapseElement = document.getElementById('collapseOne6');
    if (collapseElement) {
      collapseElement.classList.toggle('show'); 
    }
    setIsSearchToggleOpen(!isSearchToggleOpen);
  };

  const [isDisableSearch, setIsDisableSearch]                   = useState(true)

  const handleSearchObj = (name, value) => {
    if(name == 'dateTo'){
      if(new Date(value).getTime() > new Date().getTime()){
        setError("dateTo",{type:"manual",message: "manual"})
        return;
      }
      clearErrors(name);
    }
    if(name == 'dateFrom'){
      if(new Date(value).getTime() > new Date().getTime()){
        setError("dateFrom",{type:"manual",message: "manual"})
        return;
      }
     clearErrors(name);
    }
    
    let search = searchObj;
    search[name] = value;
 
    setSearchObj(search);
    if (search?.dateFrom && search?.dateTo) {
      if (Moment(search?.dateFrom).isAfter(search?.dateTo) && !Moment(search?.dateFrom).isSame(search?.dateTo, 'day')) {
          setError("dateTo", {
            type: "manual_other",
            message: "To Date cannot be smaller than From Date",
          });
          return;
      }
    }

    if(search?.name || search?.email || search?.dateFrom || search?.dateTo){
      setIsDisableSearch(false);
    }else{
      setIsDisableSearch(true);
    }
  }

  const viewModalHandler = (id) => {
    const data = emailLog.find((email) => email._id == id);
    if (!data) {
      return;
    }
    setViewModalData(data);
    setShowModal(true);
  };

  const onSearchHandler = (data) => {
    const { name, email, contactNo, dateFrom, dateTo } = data;

     let error = 0;
    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo) && !Moment(dateFrom).isSame(dateTo, 'day')) {
        setError("dateTo", {
          type: "manual_other",
          message: "To Date cannot be smaller than From Date",
        });
        error = 1;
      }
    }
    if(new Date(dateTo).getTime() > new Date().getTime()){
      setError("dateTo",{type:"manual",message: "manual"})
      error = 1;
    }
    if(new Date(dateFrom).getTime() > new Date().getTime()){
      setError("dateFrom",{type:"manual"})
      error = 1;
    }
    if(error == 1){
      return;
    }
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        email,
        contactNo,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("name");
    resetField("email");
    resetField("contactNo");
    resetField("dateFrom");
    resetField("dateTo");
    setIsDisableSearch(true);
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { name, email, contactNo, dateFrom, dateTo } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        name,
        email,
        contactNo,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { name, email, contactNo, dateFrom, dateTo } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          name,
          email,
          contactNo,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          name,
          email,
          contactNo,
          dateFrom,
          dateTo
        ) 
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };
  const InputFields = [
    {
      label: "Name",
      name: "name",
      required: false,
    },
    {
      label: "Email",
      name: "email",
      required: false,
    },
    // {
    //   label: "Contact Number",
    //   name: "contactNo",
    //   required: false,
    // },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback:  "From Date cannot be greater than Current Date",
      },
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "To Date cannot be greater than Current Date",
      },
    },
  ];
  
  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title={"Contact Us Logs"}
        links={[{ to: "/", name: "Dashboard" }]}
      />
      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <Link to="/setting/prefix/Contact" className="btn btn-primary mx-2">
                     {"Settings"}
                    </Link>
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      onClick={handleToggle}
                    >
                      Search {isSearchToggleOpen ? <ArrowUp/> : <ArrowDown/>}
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput2
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                handleSearchObj={handleSearchObj}
                              />
                            ))}
                          </div>
                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                            isDisableSearch={isDisableSearch}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={emailLog}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: false,
                          name: "View",
                          title: "View",
                          click: viewModalHandler,
                          access: [1],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={emailLog.length}
                      />
                    )}

                    <Modal
                      isOpen={showModal}
                      ariaHideApp={false}
                      className="model_block"
                      onRequestClose={() => setShowModal(false)}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">{"Email Detail"}</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShowModal(false)}
                            >
                              <i aria-hidden="true" className="ki ki-close"></i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group row my-2">
                                  <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                    {"Name"}
                                  </label>
                                  <div className="col-12">
                                    <span className="font-size-sm text-muted font-weight-bold mt-1r">
                                      {viewModalData?.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group row my-2">
                                  <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                    {"Email"}
                                  </label>
                                  <div className="col-12">
                                    <span className="font-size-sm text-muted font-weight-bold mt-1r">
                                      {viewModalData?.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="form-group row my-2">
                              <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                {"Contact Number"}
                              </label>
                              <div className="col-12">
                                <span className="font-size-sm text-muted font-weight-bold mt-1r">
                                  {viewModalData?.countryCode?.dial_code} {viewModalData?.contactNo}
                                </span>
                              </div>
                            </div>
                            <hr />
                            <div className="form-group row my-2">
                              <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                {"Message"}
                              </label>
                              <div className="col-12">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: viewModalData?.message,
                                  }}
                                  className="font-size-sm text-muted font-weight-bold mt-1r"
                                ></span>
                              </div>
                            </div>
                            <div className="clearfix">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
