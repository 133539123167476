import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import {
  View,
  Edit,
  Delete,
  MakeDeactivate,
  MakeActivate,
  ChangePassword,
  SendCreds,
  Wallet,
  Login,
} from "../../util/Svg";
import { API } from "../../constant/api";

const truncate = (input) =>
  input.length > 200 ? `${input.substring(0, 200)}...` : input;

  
  
const Table = ({
  mainData,
  tableHeading, //keys
 
  tableData, //values
  links,
  sortingHandler,
  currentSort,
  onlyDate,
  page,
  dontShowSort = [],
  renderAs = {},
  // status,
}) => {



  const { date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  return (
    <div className="table-responsive">
      <table
        className="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center"
        id="taskTable"
      >
        <thead>
          <tr className="text-uppercase">
            {tableHeading.map((heading, index) => (
              <th
                onClick={() => sortingHandler(heading)}
                key={index}
                className={`${
                  currentSort.sortBy == heading
                    ? `sorting_${currentSort.order}`
                    : dontShowSort.includes(heading)
                    ? ""
                    : "sorting"
                }`}
                style={{
                  width:
                    heading === "answer"
                      ? "400px"
                      : heading === "description" || heading === "title"
                      ? "200px"
                      : heading === "phone number"
                      ? "120px"
                      : "",
                }}
              >
                <a className="no_sort">{heading}</a>
              </th>
            ))}
            {links && links.length > 0 ? (
              <th className="text-left ActionText">Action</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {mainData.length > 0 &&
            mainData.map((data) => (
              <tr key={data._id}>
                {tableData.map((tData, index) => {
                  let value;
                  if (tData == "description") {
                    value = (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncate(data[tData]),
                        }}
                      ></p>
                    );
                  }else if (tData == "country") {
                    value = data?.other_fields?.country?.name;
                  } else if (tData == "sport") {
                    value = data?.other_fields?.sportName?.name;
                  } else if (tData == "contact") {
                    let phoneNumber = data?.other_fields?.contact;
                    if(phoneNumber){
                      if(data.other_fields && data.other_fields.countryCode && data.other_fields.countryCode.dial_code){
                        phoneNumber = data.other_fields.countryCode.dial_code+' '+phoneNumber;
                      }
                    }else{
                      phoneNumber = '-';
                    }

                    value = phoneNumber;
                  }
                  else if (tData == "contactNo") {
                    let phoneNumber = data?.contactNo;
                    if(phoneNumber){
                      if(data.countryCode){
                        phoneNumber = data.countryCode.dial_code+' '+phoneNumber;
                      }
                    }else{
                      phoneNumber = '-';
                    }

                    value = phoneNumber;
                  }
                   else if (Object.keys(onlyDate).includes(tData)) {
                    if (onlyDate[tData] === "date") {
                      value = (
                        <Moment format={date_format}>{data[tData]}</Moment>
                      );
                    } else if (onlyDate[tData] === "dateTime") {
                      value = (
                        <Moment format={date_time_format}>{data[tData]}</Moment>
                      );
                    }
                  } else if (Object.keys(renderAs).includes(tData)) {
                    value = renderAs[tData](data[tData], data._id, data);
                  } else if (tData == "isVerified") {
                    if (data[tData] == true) {
                      value = "Yes";
                    } else {
                      value = "No";
                    }
                  } else if (tData == "isActive") {
                    if (data[tData] == true || data[tData] == "true") {
                      value = (
                        <span className="label label-lg label-light-success label-inline">
                          Activated
                        </span>
                      );
                    } else {
                      value = (
                        <span className="label label-lg label-light-danger label-inline">
                          Deactivated
                        </span>
                      );
                    }
                  } else if (tData == "approvalStatus") {
                    if (data[tData] == true || data[tData] == "Approved") {
                      value = (
                        <span className="label label-lg label-light-success label-inline">
                          Approved
                        </span>
                      );
                    } else if (
                      data[tData] == true ||
                      data[tData] == "Rejected"
                    ) {
                      value = (
                        <span className="label label-lg label-light-danger label-inline">
                          Rejected
                        </span>
                      );
                    } else {
                      value = (
                        <span className="label label-lg label-light-warning label-inline">
                          Pending
                        </span>
                      );
                    }
                  } else if (tData == "message") {
                    value = truncate(data[tData]);
                  } else if (tData == "amount") {
                    value = `$${data[tData]}`;
                  } else if (tData == "image" && typeof data[tData] !='undefined') {
                    value = (
                      <img
                        src={`${API.PORT}/${data[tData]}`}
                        alt=""
                        data-fancybox
                        height={50}
                        width={80}
                        style={{ cursor: "pointer" }}
                      />
                    );
                  } else if (tData === "answer") {
                    value = (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncate(data[tData]),
                        }}
                      ></p>
                    );
                  } else {
                    value = data[tData];
                  }

                  return (
                    <td key={index} className="py-5">
                      <div className="d-flex align-items-center">
                        <div className="text-dark-75 mb-1  font-size-lg">
                          {value}
                          {/* <span className="False_text">False</span>  
                        <span className="true_text">True</span>   */}

                          {/* {tData === "description" ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: truncate(data[tData]),
                              }}
                            ></p>
                          ) : tData === "createdAt" ? (
                            onlyDate ? (
                              <Moment format={date_format}>
                                {data[tData]}
                              </Moment>
                            ) : (
                              <Moment format={date_time_format}>
                                {data[tData]}
                              </Moment>
                            )
                          ) : tData === "isActive" ? (
                            data[tData] == "true" ? (
                              <span className="label label-lg label-light-success label-inline">
                                Activated
                              </span>
                            ) : (
                              <span className="label label-lg label-light-danger label-inline">
                                Deactivated
                              </span>
                            )
                          ) : (
                            data[tData]
                          )} */}
                        </div>
                      </div>
                    </td>
                  );
                })}

                <td className="text-left pr-2">
                  {links?.map((link, index) => {
                    let name = link.name;
                    let svg;

                    if (name == "Edit") {
                      svg = <Edit />;
                    } else if (name == "Delete") {
                      svg = <Delete />;
                    } else if (name == "View") {
                      svg = <View />;
                    } else if (
                      name == "Deactivate" &&
                      data.isActive != true &&
                      data.isActive != "true"
                    ) {
                      svg = <MakeActivate />;
                    } else if (
                      name == "Activate" &&
                      data.isActive != false &&
                      data.isActive != "false"
                    ) {
                      svg = <MakeDeactivate />;
                    } else if (
                      name == "Rejected" &&
                      data.approvalStatus == "Pending"
                    ) {
                      svg = <MakeActivate />;
                    } else if (
                      name == "Approved" &&
                      data.approvalStatus == "Pending"
                    ) {
                      svg = <MakeDeactivate />;
                    } else if (name == "ChangePassword") {
                      svg = <ChangePassword />;
                    } else if (name == "SendCreds") {
                      svg = <SendCreds />;
                    } else if (name == "Wallet") {
                      svg = <Wallet />;
                    } else if (name == "Login") {
                      svg = <Wallet />;
                    }

                    if (link.isLink) {
                      return (
                        <Link
                          key={index}
                          to={
                            link.extraData
                              ? { pathname: `${link.to}/${data._id}`, page }
                              : `${link.to}/${data._id}`
                          }
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-container="body"
                          data-boundary="window"
                          title={link.title || name}
                          data-original-title={link.title || name}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            {svg}
                          </span>
                        </Link>
                      );
                    } else {
                      return (
                        <Fragment key={index}>
                          {svg && (
                            <a
                              key={index}
                              className={`btn btn-icon btn-light mr-2 ${
                                name === "Delete"
                                  ? "btn-hover-danger confirmDelete"
                                  : "btn-hover-primary"
                              }  btn-sm `}
                              data-toggle="tooltip"
                              data-placement="top"
                              data-container="body"
                              data-boundary="window"
                              title={link.title || name}
                              data-original-title={link.title || name}
                            >
                              <span
                                onClick={() =>
                                  link.click(
                                    data._id,
                                    name,
                                    data?.organizationCode
                                  )
                                }
                                className={`svg-icon svg-icon-md ${
                                  name === "Delete"
                                    ? "svg-icon-danger"
                                    : "svg-icon-primary"
                                }`}
                              >
                                {svg}
                              </span>
                            </a>
                          )}
                        </Fragment>
                      );
                    }
                  })}
                </td>
              </tr>
            ))}
          {mainData.length == 0 && (
            <tr>
              <td colSpan="9" style={{ textAlign: "center" }}>
                {" "}
                Record not found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
