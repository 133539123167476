import { Dashboard, Settings, SystemManagement, Seeker, Users, Subscriptions } from "./Svg";

export const menu = [
  {
    name: "Dashboard",
    path: "/",
    Svg: Dashboard,
    subMenu: [],
    highlight: ["/my-profile", "/change-password"],
    subHighlight: [],
  },
  // {
  //   name: "Athletes",
  //   path: "/athletes",
  //   Svg: Seeker,
  //   subMenu: [],
  //   highlight: ["/athletes/add"],
  //   subHighlight: [
  //     "/athletes/view",
  //     "/athletes/edit",
  //     "/athletes/change-password",
  //   ],
  // },
  // {
  //   name: "Coaches",
  //   path: "/coaches",
  //   Svg: Seeker,
  //   subMenu: [],
  //   highlight: ["/coach/add"],
  //   subHighlight: [
  //     "/coach/view",
  //     "/coach/edit",
  //     "/coach/change-password",
  //   ],
  // },
  // {
  //   name: "Clubs",
  //   path: "/clubs",
  //   Svg: Seeker,
  //   subMenu: [],
  //   highlight: ["/club/add"],
  //   subHighlight: [
  //     "/club/view",
  //     "/club/edit",
  //     "/club/change-password",
  //   ],
  // },

  {
    name: "User Management",
    Svg: Users,
    subMenu: [
      {
        name: "Athletes",
        path: "/athletes",
        highlight: ["/athletes/add"],
        subHighlight: [
          "/athletes/view",
          "/athletes/edit",
          "/athletes/change-password",
        ],
      },
      {
        name: "Coaches",
        path: "/coaches",
        highlight: ["/coach/add"],
        subHighlight: ["/coach/view", "/coach/edit", "/coach/change-password"],
      },
      {
        name: "Clubs",
        path: "/clubs",
        highlight: ["/club/add"],
        subHighlight: ["/club/view", "/club/edit", "/club/change-password"],
      },
    ],
    highlight: ["/athletes"],
    subHighlight: [
      "/athletes/add",
      "/athletes/edit",
      "/athletes/view",
      "/coach/add",
      "/coach/edit",
      "/coach/view",
      "/club/add",
      "/club/edit",
      "/club/view",
      "/club/change-password",
      "/coach/change-password",
    ],
  },

  {
    name: "Basic Dashboard",
    Svg: Seeker,
    subMenu: [
      {
        name: "Slider",
        path: "/dashboard/slider",
        highlight: ["/dashboard/slider/add"],
        subHighlight: ["/dashboard/slider/view", "/dashboard/slider/edit"],
      },
      {
        name: "Tabs",
        path: "/dashboard/tabs",
        highlight: ["/dashboard/tab/add"],
        subHighlight: ["/dashboard/tab/edit"],
      },
      {
        name: "Service Description",
        path: "/dashboard/service-descriptions",
        highlight: ["/dashboard/service-description/add"],
        subHighlight: [
          "/dashboard/service-description/view",
          "/dashboard/service-description/edit",
        ],
      },
      {
        name: "Features",
        path: "/dashboard/features",
        highlight: ["/dashboard/features"],
        subHighlight: ["/dashboard/features"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
  },
  {
    name: "Sports Management",
    path: "/sports",
    Svg: SystemManagement,
    subMenu: [],
    highlight: ["/sport/add"],
    subHighlight: [
      // "/sport/view",
      "/sport/edit",
    ],
  },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    Svg: Subscriptions,
    subMenu: [],
    highlight: ["/subscription/add"],
    subHighlight: ["/subscription/edit"],
  },
  {
    name: "System Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Cms Pages",
        path: "/cms",
        highlight: ["/cms/add"],
        subHighlight: ["/cms/view", "/cms/edit"],
      },
      {
        name: "Plans",
        path: "/plans",
        highlight: ["/plan/add"],
        subHighlight: ["/plan/edit"],
      },
      {
        name: "Seo Pages",
        path: "/seo-pages",
        highlight: ["/seo-page/add"],
        subHighlight: ["/seo-page/edit"],
      },
      {
        name: "Email Templates",
        path: "/email-template",
        highlight: ["/email-template/add"],
        subHighlight: ["/email-template/edit"],
      },
      {
        name: "Email Logs",
        path: "/email-logs",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "FAQs",
        path: "/faq",
        highlight: ["/faq/add"],
        subHighlight: ["/faq/edit", "/faq/view"],
      },
      {
        name: "System Images",
        path: "/system-images",
        highlight: ["/system-images", "/system-images/add"],
        subHighlight: ["/system-images/edit"],
      },
      {
        name: "Newsletter Subscriptions",
        path: "/newsletter-subscriptions",
        highlight: ["/newsletter-subscriptions", "/newsletter-subscriptions"],
        subHighlight: ["/newsletter-subscriptions"],
      },
      {
        name: "Contact Us",
        path: "/contact-us",
        highlight: ["/contact-us", "/setting/prefix/Contact"],
        subHighlight: ["/setting/prefix/Contact"],
      },
      {
        name: "Request For Demo",
        path: "/request-for-demo",
        highlight: ["/request-for-demo", "/request-for-demo"],
        subHighlight: ["/request-for-demo"],
      },
      {
        name: "RPE Color Code",
        path: "/rpe-color-codes",
        highlight: ["/rpe-color-codes"],
        subHighlight: ["/rpe-color-codes"],
      },
      {
        name: "Language Setting",
        path: "/language-setting",
        highlight: ["/language-setting"],
        subHighlight: ["/language-setting"],
      },
      {
        name: "Contact Us Setting",
        path: "/contact-us-setting",
        highlight: ["/contact-us-setting"],
        subHighlight: ["/contact-us-setting"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
  },
  {
    name: "Settings",
    Svg: Settings,
    subMenu: [
      {
        name: "Site Setting",
        path: "/setting/prefix/Site",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Reading Setting",
        path: "/setting/prefix/Reading",
        highlight: [],
        subHighlight: [],
      },
      {
        name: "Social Setting",
        path: "/setting/prefix/Social",
        highlight: [],
        subHighlight: [],
      },
    ],
    highlight: ["/setting", "/setting/add", "/employee-count/add"],
    subHighlight: ["/setting/edit", "/employee-count/edit"],
  },
];
