import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";
import ViewAll from "./ViewAllRoll";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const { id: seekerId } = props.match.params;

  const [userData, setUserData] = useState([]);

  const { response: responseUserData, request: requestUserData } = useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestUserData("GET", `coach/${seekerId}`);

    document.title = "View Coach - Calevalab";
  }, []);

  useEffect(() => {
    if (responseUserData) {
      const {
        firstName,
        lastName,
        email,
        isActive,
        createdAt,
        other_fields,
        organizationCode,
      } = responseUserData.user;

      setUserData([
        { title: "First Name", value: firstName ? firstName : "-" },
        { title: "Last Name", value: lastName ? lastName : "-" },
        { title: "Email", value: email ? email : "-" },
        {
          title: "Profile Image",
          value:
            other_fields && other_fields.profilePic ? (
              <img
                className="imageMedia"
                src={`${API.PORT}/${other_fields.profilePic}`}
              />
            ) : (
              "-"
            ),
        },
        {
          title: "Organization Invitation Code",
          value: organizationCode ? organizationCode : "-",
        },
        {
          title: "Experience(in years)",
          value:
            other_fields && other_fields.experience
              ? other_fields.experience
              : "-",
        },
        {
          title: "Qualification",
          value:
            other_fields && other_fields.qualification
              ? other_fields.qualification
              : "-",
        },
        {
          title: "Certification",
          value:
            other_fields && other_fields.certifications
              ? other_fields.certifications
              : "-",
        },
        {
          title: "Description/Bio",
          value: other_fields && other_fields.bio ? other_fields.bio : "-",
        },
        {
          title: "Registered On",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
        {
          title: "Status",
          value: isActive == true ? <Activated /> : <Deactivated />,
        },
      ]);
    }
  }, [responseUserData]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Coach"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/coaches", name: "Back To Coaches" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Coach Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                  {["Role Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={1} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_0`}
                  role="tabpanel"
                >
                  {userData.length > 0 &&
                    userData.map((user, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {user.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {user.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
                <div
                  className={`tab-pane `}
                  id={`kt_apps_contacts_view_tab_1`}
                  role="tabpanel"
                >
              
                  <ViewAll userData={responseUserData?.roles || []} />

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
