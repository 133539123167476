import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";

import {
  Input,
  RenderInputFields,
  SubmitButton,
  CKEditorInput,
  Textarea,SubInput,
  SubTab
} from "../../Form/Form";
import { compareChanges } from "../../../util/fn";
import { CHANGES_REQ_MESSAGE } from "../../../util/other";


const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset
  } = useForm();

  const ckEditorRef = useRef();
  const { response, request } = useRequest();
  const { languages } = useSelector((state) => state.setting);
  const { response: responseFeatures, request: requestFeatures } = useRequest();

  const [image, setImage] = useState();
  const [displayImage, setDisplayImage] = useState();
  const [recordId, setRecordId] = useState();
  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    document.title = "Features - Calevalab";
    requestFeatures("GET", "home-feature");
  }, [response]);

  useEffect(() => {
    if (responseFeatures) {
      if (responseFeatures.data ) {
        const {langData,image,video} = responseFeatures?.data ?? {};
        if (responseFeatures.data._id) {
          setRecordId(responseFeatures.data?._id);
        }
        if (responseFeatures.data.image) {
          setImage(responseFeatures.data.image);
          setValue("image", responseFeatures.data?.image);
        }
        if (responseFeatures.data.video) {
          setValue("video", responseFeatures.data?.video);
        }
        if (responseFeatures.data.description) {
          setValue("description", responseFeatures.data?.description);
        }


        // const langDataWithoutId = langData.map(({ _id: _, ...rest }) => rest);
  
        const langDataWithoutId = langData.map(({ _id: _, link: __, features, ...rest }) => ({
          ...rest,
          features: features.map(({ _id, ...featureRest }) => featureRest) // Remove '_id' from each feature
        }));
      
        const langDataShortedOld = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));
        setOldValues({
          image: image,
          video: video,
          langData:langDataShortedOld
        });

        const subData   = {};
        if(langData?.length>0){
          langData.forEach((lang) => {
            const code = lang?.languageCode;
            const features = lang?.features;
            subData["id-" + code] = lang?._id;
            subData["description-" + code] = lang?.description;
            features.forEach((obj, index) => {

              subData[`title${index + 1}-${code}`] =  obj.title;
              subData[`description${index + 1}-${code}`] =  obj.description;
            }); 
          });
          reset({ ...subData });
        }

      }
    }
  }, [responseFeatures]);

  useEffect(() => {
   
    if (response) {
      if (response.data) {
       
        if (response.data._id) {
          setRecordId(response.data._id);
        }
        if (response.data.image) {
          setImage(response.data.image);
          setValue("image", response.data.image);
        }
        if (response.data.video) {
          setValue("video", response.data.video);
        }
        if (response.data.description) {
          setValue("description", response.data.description);
        }
        if (response.data.features && response.data.features.length > 0) {
          response.data.features.forEach((obj, index) => {
            setValue(`title${index + 1}`, obj.title);
            setValue(`description${index + 1}`, obj.description);
          });
        }
      }
      setDisplayImage(null);
      if (document.getElementById("image")) {
        document.getElementById("image").value = "";
      }
      toast.success(response.message);
    }
  }, [response]);

  const onSubmit = (data) => {
    const langData                     = [];
    const {
      description,
      video,
      title1,
      description1,
      title2,
      description2,
      title3,
      description3,
      title4,
      description4,
    } = data;

    let fd = new FormData();

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;

      if (languages[i].default) {
        fd.append("description", data[`description-${code}`]);
      
        const featuresDefault = [
          {
            title: data[`title1-${code}`],
            description: data[`description1-${code}`],
          },
          {
            title: data[`title2-${code}`],
            description: data[`description2-${code}`],
          },
          {
            title: data[`title3-${code}`],
            description: data[`description3-${code}`],
          },
          {
            title: data[`title4-${code}`],
            description: data[`description4-${code}`],
          },
        ];
        fd.append("features", JSON.stringify(featuresDefault));
      }
      const features = [
        {
          title: data[`title1-${code}`],
          description: data[`description1-${code}`],
        },
        {
          title: data[`title2-${code}`],
          description: data[`description2-${code}`],
        },
        {
          title: data[`title3-${code}`],
          description: data[`description3-${code}`],
        },
        {
          title: data[`title4-${code}`],
          description: data[`description4-${code}`],
        },
      ];

      
      langData.push({ id: data[`id-${code}`],languageCode:code, description: data[`description-${code}`],features:features});
    }


    if (displayImage) {
      if (data.image && data.image.length > 0) {
        fd.append("file", data.image[0]);
      }
    } else {
      if (image) {
        fd.append("old_image", image);
      }
    }

    if (recordId) {
      fd.append("id", recordId);
    }
    fd.append("video", video);

    const langDataWithoutId = langData.map(({ id: _,  ...rest }) => rest);
    const langDataShorted = langDataWithoutId.sort((a, b) => a.languageCode.localeCompare(b.languageCode));

    let newData = {
      image: (data?.image?.length > 0 && data?.image[0]?.type) ? data.image[0] : image,
      video: data?.video,
      langData:langDataShorted
    };

   
    if(compareChanges(oldValues,newData)){
      fd.append("langData", JSON.stringify(langData));
      if (recordId) {
        request("PUT", "home-feature", fd);
      }else {
        request("POST", "home-feature", fd);
      }
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }
  };

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setError("image", "");
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
    if (document.getElementById("image")) {
      document.getElementById("image").value = "";
    }
  };

  const InputFields = [
    [
      {
        Component: Textarea,
        placeholder: "Enter Description",
        label: "Description",
        name: "description",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title1",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z!@#$%^&*() ]+$/,
          setValueAs: (v) => v.trim(),
        },
        // registerFieldsFeedback: {
        //   pattern: "Title can only contain letters.",
        // },
      },
      {
        Component: Textarea,
        placeholder: "Enter Description",
        label: "Description",
        name: "description1",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title2",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z!@#$%^&*() ]+$/,
           setValueAs: (v) => v.trim(),
        },

        // registerFieldsFeedback: {
        //   pattern: "Title can only contain letters.",
        // },
      },
      {
        Component: Textarea,
        placeholder: "Enter Description",
        label: "Description",
        name: "description2",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title3",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z!@#$%^&*() ]+$/,
          setValueAs: (v) => v.trim(),
        },
        // registerFieldsFeedback: {
        //   pattern: "Title can only contain letters.",
        // },
      },
      {
        Component: Textarea,
        placeholder: "Enter Description",
        label: "Description",
        name: "description3",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ],
    [
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title4",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z!@#$%^&*() ]+$/,
          setValueAs: (v) => v.trim(),
        },
        // registerFieldsFeedback: {
        //   pattern: "Title can only contain letters.",
        // },
      },
      {
        Component: Textarea,
        placeholder: "Enter Description",
        label: "Description",
        name: "description4",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ],
  ];

  const InputFieldsImage = [
    [
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "image",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
      },
      {
        Component: Input,
        label: "Video",
        type: "text",
        name: "video",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Features"
        links={[
          { to: "/", name: "Dashboard" },
          // { to: "/home/service-descriptions", name: "Back To Service Descriptions" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">Features</h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                />
                             ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <>
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  required={true}
                                  InputFields={InputFields}
                                  code={lang.code}
                                />
                              </>
                            ))}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                  
                      <RenderInputFields
                        InputFields={InputFieldsImage}
                        errors={errors}
                        register={register}
                      />
                      
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
