import React,  { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from 'moment';

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton,Textarea, SelectInput } from "../Form/Form";
import { DefaultCountryCode ,NAME_LIMIT,CHANGES_REQ_MESSAGE} from "../../util/other";
import { compareChanges } from "../../util/fn";

const Edit = (props) => {
  const { id: seekerId } = props.match.params;
  const history = useHistory();
  const [oldValues, setOldValues]                     = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    control
  } = useForm();

  const { response: responseFetchUser, request: requestFetchSeeker } =
    useRequest();

  const { response, request }                                     = useRequest();
  const { request: requestSport, response: responseSport}         = useRequest();
  const { request: requestCountry, response: responseCountry}     = useRequest();
  const { request: requestCCodes, response: responseCCodes}       = useRequest();
  const [allSports, setAllSports]                                 = useState([]);
  const [allCountry, setAllCountry]                               = useState([]);
  const [image, setImage]                                         = useState();
  const [displayImage, setDisplayImage]                           = useState();
  const [selectedSport, setSelectedSport]                         = useState();
  const [selectedCountry, setSelectedCountry]                     = useState();
  const [allCountryCodes, setAllCountryCodes]                     = useState([]);

  const minDateOfBirth = moment().subtract(1,'days').format("YYYY-MM-DD")+' 23:59:59';

  useEffect(() => {
    if (seekerId) {
      requestFetchSeeker("GET", `club/${seekerId}`);
      requestSport("GET",`master/all?page=1&isActive=${true}`)
      requestCountry("GET",`country/all?page=1&isActive=${true}`)
      requestCCodes("GET",`country-codes/all`)
      document.title = "Edit Club - Calevalab";
    }
  }, [seekerId]);

  useEffect(() => {
    if(responseSport && responseSport.status){
      setAllSports(responseSport.sports)
    }
  },[responseSport])

  useEffect(() => {
    if(responseCountry && responseCountry.status){
      setAllCountry(responseCountry.data)
    }
  },[responseCountry])
  
  useEffect(() => {
    if(responseCCodes && responseCCodes.status){
      setAllCountryCodes(responseCCodes.data)
    }
  },[responseCCodes])

  useEffect(() => {
    if (responseFetchUser) {
      const { firstName, lastName, email,organizationCode,other_fields } = responseFetchUser.user;

      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      setValue("organizationCode", organizationCode);
      setValue("gender", (other_fields && other_fields.gender) ? other_fields.gender:'');
      setValue("dob", (other_fields && other_fields.dob) ? moment(other_fields.dob).format("YYYY-MM-DD"):'');
      setValue("country", (other_fields && other_fields.country && other_fields.country._id)?other_fields.country._id:'');
      setValue("organizationName", (other_fields && other_fields.organizationName) ? other_fields.organizationName:'');
      setValue("organizationShortName", (other_fields && other_fields.organizationShortName) ? other_fields.organizationShortName:'');
      setValue("organizationAddress", (other_fields && other_fields.organizationAddress) ? other_fields.organizationAddress:'');
      setValue("organizationCity", (other_fields && other_fields.organizationCity) ? other_fields.organizationCity:'');
      setValue("sport", (other_fields && other_fields.sportName && other_fields.sportName._id)?other_fields.sportName._id:'');
      setValue("organizationWebsite", (other_fields && other_fields.organizationWebsite) ? other_fields.organizationWebsite:'');
      setValue("contact", (other_fields && other_fields.contact) ? other_fields.contact:'');
      if(other_fields && other_fields.countryCode && other_fields.countryCode._id){
        setValue("countryCode", (other_fields && other_fields.countryCode && other_fields.countryCode._id)?other_fields.countryCode._id:'');
      }else{
        if(allCountryCodes && allCountryCodes.length>0){
          const selectedCodeObj = allCountryCodes.find(obj => {
            if(obj.dial_code == DefaultCountryCode.CountryCode){
              return obj;
            } 
          })
          setValue('countryCode',selectedCodeObj?._id)
        }
      }

      setValue("image", (other_fields && other_fields.profilePic) ? other_fields.profilePic:'');
      setImage(other_fields && other_fields.profilePic ? other_fields.profilePic:'');
      setSelectedSport((other_fields && other_fields.sportName && other_fields.sportName._id)?other_fields.sportName._id:'')
      setSelectedCountry( (other_fields && other_fields.country && other_fields.country._id)?other_fields.country._id:'')


      setOldValues({
        firstName: firstName,
        lastName: lastName,
        email: email,
        organizationCode: organizationCode,
        gender: other_fields?.gender || '',
        dob: other_fields?.dob ? moment(other_fields.dob).format("YYYY-MM-DD") : '',
        country: other_fields?.country?._id || '',
        organizationName: other_fields?.organizationName || '',
        organizationShortName: other_fields?.organizationShortName || '',
        organizationAddress: other_fields?.organizationAddress || '',
        organizationCity: other_fields?.organizationCity || '',
        sport: other_fields?.sportName?._id || '',
        organizationWebsite: other_fields?.organizationWebsite || '',
        contact: other_fields?.contact || '',
      
        // CountryCode logic
        countryCode: other_fields?.countryCode?._id || 
          (allCountryCodes?.length > 0 
            ? allCountryCodes.find(obj => obj.dial_code === DefaultCountryCode.CountryCode)?._id || '' 
            : ''),
      
        // Image logic
        image: other_fields?.profilePic || ''
      });
      
      
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Club has been updated successfully.");
      history.push("/clubs");
    }
  }, [response]);


  const handleDate = (e, name) => {
    if(name == 'dob'){
      setValue('dob',e.target.value)
      clearErrors("dob")
    }
  }
  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const typeArr = event.target.files[0].type.split("/");
      if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
        setError("image", {type:"manual"});
        setValue("image", event.target.files[0]);
        setDisplayImage("")
        setImage("");
        return;
      }
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      clearErrors("image");
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (displayImage) {
      setDisplayImage(null);
      setValue("image", null);
    } else {
      setImage(null);
    }
  };

  const onSubmit = (data) => {
    const {   firstName,
      lastName,
      gender,
      dob,
      country,
      organizationName,
      organizationShortName,
      organizationAddress,
      organizationCity,
      organizationCode,
      sport,
      organizationWebsite,
      email,
      contact,countryCode} = data;

    const { email: oldEmail } = responseFetchUser.user;

    let updates = {};

    if (email.trim() != oldEmail) {
      updates.email = email;
    }

    let fd = new FormData();
    let error = 0;
    if(displayImage){
      if (data.image && data.image.length > 0) {
        if (data.image[0].type) {
          const typeArr =  data.image[0].type.split("/");
  
          if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
            setError("image", {
              type: "manual",
            });
            error = 1;
          }else{
              fd.append("file", data.image[0]);
          }
        }
      }
    }else{
      if(image){
          fd.append("old_image", image);
      }else{
        if (data.image && data.image.length > 0) {
          if (data.image[0].type) {
            const typeArr =  data.image[0].type.split("/");
    
            if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
              setError("image", {
                type: "manual",
              });
              error = 1;
            }else{
                fd.append("file", data.image[0]);
            }
          }
        }
      }
    }
  
    if(new Date(dob).getTime() > new Date(minDateOfBirth).getTime()){
      setError("dob",{type:"manualDOB"})
      error = 1;
    }

    if(error == 1){
      return;
    }

    fd.append("firstName", firstName);
    fd.append("lastName", lastName);
    fd.append("organizationCode", organizationCode);
    fd.append("email", email);
    fd.append("gender", gender);
    fd.append("dob", dob);
    fd.append("country", country);
    fd.append("organizationName", organizationName);
    fd.append("organizationShortName", organizationShortName);
    fd.append("organizationAddress", organizationAddress);
    fd.append("organizationCity", organizationCity);
    fd.append("sportName", sport);
    fd.append("organizationWebsite", organizationWebsite);
    fd.append("contact", contact);
    fd.append("userId", seekerId);
    fd.append("countryCode",countryCode);

    let ignoreKey = [];
    if(compareChanges(oldValues,data,ignoreKey)){
      request("PUT", "club", fd);
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }

  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "firstName",
        registerFields: {
          required: true,
          // pattern: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
          pattern:/^[\S.]+(?:[\s.]+[\S.]+)*$/,
          maxLength:NAME_LIMIT,
          isNameField:true
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid first name.",
        },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "lastName",
        registerFields: {
          required: true,
          pattern:/^[\S.]+(?:[\s.]+[\S.]+)*$/,
          maxLength:NAME_LIMIT,
          isNameField:true
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid last name.",
        },
      },
      {
        Component: SelectInput,
        label: "Gender",
        name: "gender",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        children: (
          [
            { value: "", label: "Select Gender" },
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
          ]
        ),
        control:control
      },
      {
        Component: Input,
        label: "Date Of Birth",
        type: "date",
        name: "dob",
        registerFields: {
          required: true,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid date of birth.",
        },
        isDate:true,
        max:moment().subtract(1, 'days').format("YYYY-MM-DD"),
        handleDateChange:handleDate, 
        control:control
      },
      
      {
        Component: SelectInput,
        label: "Country",
        name: "country",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        children: (
          allCountryCodes && allCountryCodes.length > 0 
          ? allCountryCodes.map(obj => ({
              value: obj._id,
              label: obj.dial_code,
              selected: obj.dial_code === DefaultCountryCode?.CountryCode
            }))
          : []
        ),
        control:control
      },
    ],[
      {
        Component: Input,
        label: "Organization Name",
        type: "text",
        name: "organizationName",
        registerFields: {
          required: true,
        },
      },

      {
        Component: Input,
        label: "Organization Short Name",
        type: "text",
        name: "organizationShortName",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Textarea,
        label: "Organization Address",
        name: "organizationAddress",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Organization City",
        type: "text",
        name: "organizationCity",
        registerFields: {
          required: true,
        },
      },

      {
        Component: Input,
        label: "Code",
        type: "text",
        name: "organizationCode",
        registerFields: {
          required: true,
        },
      },
      {
        Component: SelectInput,
        label: "Sport",
        name: "sport",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        children: (
          [
            { value: "", label: "Select Sport" },  // Default option
            ...(allSports && allSports.length > 0
              ? allSports.map(obj => ({ value: obj._id, label: obj.name }))
              : []
            )
          ]
        ),
        control:control
      },
     
      {
        Component: Input,
        label: "Organization Website",
        type: "text",
        name: "organizationWebsite",
        registerFields: {
          required: false,
        },
      },
   
      {
        Component: Input,
        label: "Logo",
        type: "file",
        name: "image",
        registerFields: {
          required: false,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid image extension type.",
        },
      },
    ],

    [
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      {
        Component: SelectInput,
        label: "Country Code",
        name: "countryCode",
        colClass:"col-xl-2",
        extraClass:"countryCode-input",
        registerFields: {
          required: true,
          isNoOnChange:true
        },
        children: (
          allCountryCodes && allCountryCodes.length > 0 
          ? allCountryCodes.map(obj => ({
              value: obj._id,
              label: obj.dial_code,
              selected: obj.dial_code === DefaultCountryCode?.CountryCode
            }))
          : []
        ),
        control:control
      },
      {
        Component: Input,
        label: "Phone Number",
        type: "text",
        name: "contact",
        colClass:"col-xl-4",
        registerFields: {
          required: true,
          pattern: /^[0-9]{7,12}$/gm,
        },
        registerFieldsFeedback: {
          pattern: "Phone number must be minimum 7 and maximum 12 digit number.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Club"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/clubs" /*backPageNum: page */ },
            name: "Back To Clubs",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Club
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
