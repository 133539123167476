import React, { useEffect, useRef,useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { RenderInputFields, SubmitButton, Input } from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    error,
    clearErrors,
    setError,
    control
  } = useForm();

  const ckEditorRef = useRef();

  const { response, request } = useRequest();
  const [image, setImage]                                         = useState();
  const [ displayImage,  setDisplayImage]                           = useState();
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Language - Calevalab";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Language has been added successfully.");
      history.push("/language-setting");
    }
  }, [response]);

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const typeArr = event.target.files[0].type.split("/");
      if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
        setError("icon", {type:"manual"});
        setValue("icon", event.target.files[0]);
         setDisplayImage("")
        return;
      }
       setDisplayImage(URL.createObjectURL(event.target.files[0]));
      // setError("image", "");
      clearErrors('icon')
    } else {
       setDisplayImage("");
      setImage("");
      setValue("icon", "");
    }
  };

  const handleRemoveImage = () => {
    if ( displayImage) {
       setDisplayImage(null);
      setValue("icon", null);
    } else {
      setImage(null);
    }
  };

  const onSubmit = (data) => {
    const { language,icon } = data;
    let fd = new FormData();
    if (icon && icon.length > 0) {
        if (icon[0].type) {
          const typeArr =  icon[0].type.split("/");
          if(typeArr.length>0 && typeArr[0] && typeArr[0] != 'image'){
            setError("icon", {
              type: "manual",
            });
            return;
          }
          fd.append("file", icon[0]);
        }
    }
    fd.append("language", language);
    request("POST","language",fd);
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Language",
        name: "language",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "icon",
        registerFields: {
          required: true,
        },
        handleMedia: handleImage,
        handleRemoveMedia: handleRemoveImage,
        isMedia: true,
        accept: "image/*",
        control,
        image,
        displayImage,
        media: "image",
        otherRegisterFields: {
          manual: true,
          feedback: "Please select valid image extension type.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Language"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/language-setting", name: "Back To Languages" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Language
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                    <div className="row"></div>
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
