import React, { useEffect, useState,useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton, CKEditorInput,Textarea ,SubInput,
  SubTab } from "../../Form/Form";
import { useSelector } from "react-redux";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm();
  const { languages } = useSelector((state) => state.setting);

  const ckEditorRef = useRef();
  const { response, request } = useRequest();

  const history = useHistory();
  const [image, setImage]                                         = useState();
  const [displayImage, setDisplayImage]                           = useState();

  useEffect(() => {
    document.title = "Add Service Description - Calevalab";
    // register('description',{required:true})
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Service description has been added successfully.");
      history.push("/dashboard/service-descriptions");
    }
  }, [response]);

  const onSubmit = (data) => {
   
    const langData                     = [];
    let fd                              = new FormData();
   
    if (data.image && data.image.length > 0) {
        fd.append("file", data.image[0]);
    }

    fd.append("link", data?.link);

    for (let i = 0; i < languages.length; i++) {
      const code = languages[i].code;
      if (languages[i].default) {
        fd.append("title",  data[`title-${code}`]);
        fd.append("subTitle",  data[`subTitle-${code}`]);
        fd.append("description", data[`description-${code}`]);
     
      }
      langData.push({ languageCode:code, title: data[`title-${code}`], subTitle: data[`subTitle-${code}`], description: data[`description-${code}`]});
    }
    
    fd.append("langData", JSON.stringify(langData));
    request("POST", "home-service-description", fd);
  };

  const handleImage = (event) => {
    event.preventDefault();
    if(event.target.files[0]){
        setDisplayImage(URL.createObjectURL(event.target.files[0]))
        setError('image','');
    }else{
        setDisplayImage('');
        setImage('');
        setValue('image','')
    }
  }

  const handleRemoveImage = () => {
    if (displayImage) {
        setDisplayImage(null);
        setValue("image", null);
    } else {
        setImage(null);
    }
  }
  const InputFields = [
    [
        {
        Component: Input,
        label: "Sub Title",
        type: "text",
        name: "subTitle",
        registerFields: {
            required: true,
            // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //     pattern: "Sub title text can only contain letters.",
        // },
    },
      {
        Component: Input,
        label: "Title",
        type: "text",
        name: "title",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Title can only contain letters.",
        // },
      },
      {
        Component: Textarea,
        label: "Description",
        name: "description",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
    
    ],
  ];

  const InputFields2 = [[
    {
      Component: Input,
      label: "Link",
      type: "text",
      name: "link",
      registerFields: {
        required: true,
      },
    },
    { 
      Component: Input,
      label: "Image",
      type: "file",
      name: "image",
      registerFields: {
        required: true
      },
      handleMedia:handleImage,
      handleRemoveMedia:handleRemoveImage,
      isMedia:true,
      accept:"image/*",
      control,
      image,
      displayImage,
      media:'image'
    }
]];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Service Description"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/dashboard/service-descriptions", name: "Back To Service Descriptions" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Service Description
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row"></div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                          <ul
                            className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                            role="tablist"
                          >
                            {languages.length > 0 &&
                              languages.map((lang, index) => (
                                <SubTab
                                  key={index}
                                  name={lang.name}
                                  index={index}
                                  image={lang?.image}
                                />
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body px-0">
                        <div className="tab-content px-10">
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <>
                                <SubInput
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  register={register}
                                  //   required={lang.default}
                                  // required={true}
                                  InputFields={InputFields}
                                  code={lang.code}
                                />
                              </>
                            ))}
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    <div className="row"></div>
                    
                    <RenderInputFields
                      InputFields={InputFields2}
                      errors={errors}
                      register={register}
                    />
                     
                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
