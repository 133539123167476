import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { CHANGES_REQ_MESSAGE, DefaultCountryCode ,NAME_LIMIT } from "../../util/other";
import { compareChanges } from "../../util/fn";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
  SelectInput,
} from "../Form/Form";

const Edit = (props) => {
  const { id: subscriptionId } = props.match.params;

  const history = useHistory();

  const [clubs, setClubs] = useState([]);
  const [plans, setPlans] = useState([]);
  const [accountsCount, setAccountsCount] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
    control
  } = useForm();

  const {
    response: responseFetchSubscription,
    request: requestFetcSubscription,
  } = useRequest();

  const { response, request } = useRequest();
  const [oldValues, setOldValues]                     = useState([]);

  useEffect(() => {
    if (subscriptionId) {
      requestFetcSubscription("GET", `subscription/${subscriptionId}`);
      document.title = "Edit Subscription - Calevalab";
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (responseFetchSubscription) {
      const { clubs, plans } = responseFetchSubscription;
      const { planId, currentAccountsCount, userId } =
        responseFetchSubscription.subscription;

      setClubs(clubs);
      setPlans(plans);
      setAccountsCount(currentAccountsCount);
      setSelectedPlan(plans.find((p) => p._id === planId));

      reset({ planId, clubId: userId });
      setOldValues({
        planId: planId,
        currentAccountsCount: currentAccountsCount,
        userId: userId
      });
    }
  }, [responseFetchSubscription]);

  useEffect(() => {
    if (response) {
      toast.success("Subscription has been updated successfully.");
      history.push("/subscriptions");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { planId } = data;

    const {
      planId: oldPlanId,
      currentAccountsCount: oldCurrentAccountsCount,
      userId,
    } = responseFetchSubscription.subscription;

    // if (planId === oldPlanId && oldCurrentAccountsCount === accountsCount) {
    //   toast.success("Subscription has been updated successfully.");
    //   history.push("/subscriptions");
    //   return;
    // }
    let newData =  {
      planId:planId,
      userId:userId,
      currentAccountsCount:accountsCount
    }

    if(compareChanges(oldValues,newData)){
      request("PUT", "subscription", {
        planId,
        clubId: userId,
        accountsCount,
        id: subscriptionId,
      }); 
    }
    else {
      toast.error(CHANGES_REQ_MESSAGE);
    }


    
  };

  const planChangeHandler = (id) => {
    if (!id) {
      setSelectedPlan(null);
      setAccountsCount(null);
      return;
    }
    const plan = plans.find((p) => p._id === id);
    setSelectedPlan(plan);
    setAccountsCount(plan.accountsCount);
  };

  const changeAccountCount = (key) => {
    if (selectedPlan.price === 0) {
      return;
    }

    if (key === "add") {
      setAccountsCount((prev) => prev + selectedPlan.accountsCount);
    } else {
      if (accountsCount === selectedPlan.accountsCount) {
        return;
      }
      setAccountsCount((prev) => prev - selectedPlan.accountsCount);
    }
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Club",
        name: "clubId",
        registerFields: { required: true},
        children: (
          // <>
          //   <option value="">Select Club</option>
          //   {clubs &&
          //     clubs.length > 0 &&
          //     clubs.map((obj) => (
          //       <option key={obj._id} value={obj._id}>
          //         {obj.name}
          //       </option>
          //     ))}
          // </>
          [
            { value: '', label: 'Select Club' ,key: ''},
            ...clubs.map(obj => ({ value: obj._id, label: obj.name ,key: obj._id}))
          ]
        ),
        control:control,
        disabled:true
      },
    ],
    [
      {
        Component: SelectInput,
        label: "Plan",
        name: "planId",
        registerFields: { required: true },
        children: (
          [
            { value: '', label: 'Select Plan' },
            ...plans.map(obj => ({ value: obj._id, label: obj.name }))
          ]
          // <>
          //   <option value="">Select Plan</option>
          //   {plans &&
          //     plans.length > 0 &&
          //     plans.map((obj) => (
          //       <option key={obj._id} value={obj._id}>
          //         {obj.name}
          //       </option>
          //     ))}
          // </>
        ),
        handleChange: planChangeHandler,
        control:control
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Subscription"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/subscriptions" },
            name: "Back To Subscriptions",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Subscription
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    {accountsCount !== null && (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        <p>Accounts</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            onClick={() => changeAccountCount("subtract")}
                            class="fas fa-minus"
                          ></i>
                          <span>{accountsCount}</span>
                          <i
                            onClick={() => changeAccountCount("add")}
                            class="fas fa-plus"
                          ></i>
                        </div>
                      </div>
                    )}

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
