import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
  SelectInput,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    control

  } = useForm();

  const [clubs, setClubs] = useState([]);
  const [plans, setPlans] = useState([]);
  const [accountsCount, setAccountsCount] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const ckEditorRef = useRef();

  const { response, request } = useRequest();

  const { response: responseAddData, request: requestAddData } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Subscription - Calevalab";
    requestAddData("GET", "subscription/add-data");
  }, []);

  useEffect(() => {
    if (responseAddData) {
      setClubs(responseAddData.clubs);
      setPlans(responseAddData.plans);
    }
  }, [responseAddData]);

  useEffect(() => {
    if (response) {
      toast.success("Subscription has been added successfully.");
      history.push("/subscriptions");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { clubId, planId } = data;

    request("POST", "subscription", {
      clubId,
      planId,
      accountsCount,
    });
  };

  const planChangeHandler = (id) => {
    console.log(id);
    if (!id) {
      setSelectedPlan(null);
      setAccountsCount(null);
      return;
    }
    const plan = plans.find((p) => p._id === id);
    setSelectedPlan(plan);
    setAccountsCount(plan.accountsCount);
  };

  const changeAccountCount = (key) => {
    if (selectedPlan.price === 0) {
      return;
    }

    if (key === "add") {
      setAccountsCount((prev) => prev + selectedPlan.accountsCount);
    } else {
      if (accountsCount === selectedPlan.accountsCount) {
        return;
      }
      setAccountsCount((prev) => prev - selectedPlan.accountsCount);
    }
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Club",
        name: "clubId",
        registerFields: { required: true },
        children: (
          [
            { value: '', label: 'Select Club' ,key: ''},
            ...clubs.map(obj => ({ value: obj._id, label: obj.name ,key: obj._id}))
          ]
          // <>
          //   <option value="">Select Club</option>
          //   {clubs &&
          //     clubs.length > 0 &&
          //     clubs.map((obj) => (
          //       <option key={obj._id} value={obj._id}>
          //         {obj.name}
          //       </option>
          //     ))}
          // </>
        ),
        control:control
      },
    ],
    [
      {
        Component: SelectInput,
        label: "Plan",
        name: "planId",
        registerFields: { required: true },
        children: (
          [
            { value: '', label: 'Select Plan' },
            ...plans.map(obj => ({ value: obj._id, label: obj.name }))
          ]
        ),
        handleChange: planChangeHandler,
        control:control
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Subscription"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/subscriptions", name: "Back To Subscriptions" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Subscription
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    {accountsCount !== null && (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        <p>Accounts</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <i
                            onClick={() => changeAccountCount("subtract")}
                            class="fas fa-minus"
                          ></i>
                          <span>{accountsCount}</span>
                          <i
                            onClick={() => changeAccountCount("add")}
                            class="fas fa-plus"
                          ></i>
                        </div>
                      </div>
                    )}

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
